import React, { useState, useEffect } from "react";
import { Calendar, Badge, Modal, Input, Button, message } from "antd";
import axios from "axios";
import moment from "moment";
import { COPILOT_GET_CALANDER_EVENTS } from "../../../ApiUrl";
import { useSelector } from "react-redux";

const RecruiterCalendar = () => {
  const [tasks, setTasks] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [newTask, setNewTask] = useState("");
  const [currentMonth, setCurrentMonth] = useState(moment());
  const users = useSelector(({ users }) => users);

  // Fetch events for the current month and year
  const fetchEvents = async (month, year) => {
    try {
      const response = await axios.post(
        COPILOT_GET_CALANDER_EVENTS,
        {
          month,
          year,
        },
        {
          headers: {
            Authorization: users.token,
          },
        }
      );
      const events = response.data.data;

      // Group events by date and only show titles
      const formattedEvents = events.reduce((acc, event) => {
        const dateStr = moment(event.date).format("YYYY-MM-DD");

        // Append event title to the date
        if (!acc[dateStr]) {
          acc[dateStr] = [];
        }
        acc[dateStr].push(event.title); // Store the title for each event on that date

        return acc;
      }, {});

      setTasks(formattedEvents);
    } catch (error) {
      console.error("Error fetching events:", error);
      message.error("Failed to fetch events. Please try again.");
    }
  };

  useEffect(() => {
    // Fetch events when the current month changes
    const month = currentMonth.month() + 1; // Month is zero-based
    const year = currentMonth.year();
    fetchEvents(month, year);
  }, [currentMonth]);

  const addTask = () => {
    if (newTask.trim()) {
      const dateStr = selectedDate.format("YYYY-MM-DD");
      const currentTasks = tasks[dateStr] || [];
      setTasks({
        ...tasks,
        [dateStr]: [...currentTasks, newTask],
      });
      setNewTask("");
      setModalVisible(false);
    }
  };

  const dateCellRender = (value) => {
    const dateStr = value.format("YYYY-MM-DD");
    const taskList = tasks[dateStr] || [];

    return (
      <div>
        {taskList.length > 0 &&
          taskList.map((task, index) => (
            <Badge
              key={index}
              color="blue"
              text={task}
              style={{ display: "block", marginBottom: 4 }}
            />
          ))}
        {/* If there are multiple events, just display the titles */}
        {taskList.length > 1 && (
          <Badge color="gray" text={`+${taskList.length - 1} more`} />
        )}
      </div>
    );
  };

  const handleSelect = (date) => {
    setSelectedDate(date);
    setModalVisible(true);
  };

  const handlePanelChange = (value) => {
    setCurrentMonth(value);
  };

  return (
    <div style={{ padding: "20px" }}>
      <Calendar
        dateCellRender={dateCellRender}
        onSelect={handleSelect}
        onPanelChange={handlePanelChange}
        style={{
          border: "1px solid #d9d9d9",
          borderRadius: "8px",
          padding: "10px",
        }}
      />
      <Modal
        title={`Add Task for ${
          selectedDate ? selectedDate.format("YYYY-MM-DD") : ""
        }`}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
        <Input
          placeholder="Enter Task"
          value={newTask}
          onChange={(e) => setNewTask(e.target.value)}
          style={{ marginBottom: "10px" }}
        />
        <Button type="primary" onClick={addTask}>
          Add Task
        </Button>
      </Modal>
    </div>
  );
};

export default RecruiterCalendar;
