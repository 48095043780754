/* eslint-disable no-lone-blocks */
import React, { useEffect, useState } from "react";
import { Col, Row, Card, Tabs } from "antd";

import axios from "axios";

import CandidatesbyMe from "./CandidatesbyMe";
import OnHoldCandidates from "./OnHoldCandidates";
import RejectedCandidate from "./RejectedCandidate";
import CandidatesbyRecruiter from "./CandidatesbyRecruiter";
import CandidatesbyBDM from "./CandidatesbyBDM";
import CandidatesbyOtherBDM from "./CandidatesbyOtherBDM";

import {
  JOB_DETAIL,
  SUBMISSION_BY_BDM,
  CANDIDATE_SUBMISSION_RE_SUBMISSION,
  ASSIGN_JOB,
  JOB_RECRUITER_LIST,
  BDM_FREELANCER_LIST,
  BDM_UPDATE_SUBMISSION_COUNT,
  JOB_ASSIGNMENT_DETAILS,
} from "../../../ApiUrl";
import { useSelector } from "react-redux";

import { useLocation, useParams } from "react-router-dom";
import ActivityLogUI from "../../modules/ActivityLog";
import { showError } from "../utils/helpers";
import OpeningDetailUi from "../../modules/Openings/OpeningDetailUI";
import AssignJob from "../../modules/Openings/assignJob";
import BooleanString from "../../modules/Openings/BooleanString";

const { TabPane } = Tabs;

export default function OpeningsDetail(props) {
  const { openingId } = useParams();
  const [openingDetails, setOpeningDetails] = useState("");
  const [selectedRecruter, setSelectedRecruter] = useState([]);
  const [selectedFreelancer, setSelectedFreelancer] = useState([]);
  const [selectedBDM, setSelectedBDM] = useState([]);

  const [loading, setLoading] = useState(false);
  const [loadingUi, setLoadingUi] = useState(true);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msgSuccess, setMsgSuccess] = useState("");
  const [msgError, setmsgError] = useState("");
  const [isOpeningUpdate, setOpeningUpdate] = useState(false);

  const users = useSelector(({ users }) => users);
  let location = useLocation();

  useEffect(() => {
    getOpeningDetail();

    return () => {
      setLoadingUi(false);
      console.log("calling: ", loadingUi);
    };
  }, []);

  useEffect(() => {
    updateSubmissionCount();
    return () => {};
  }, [openingDetails]);

  const getOpeningDetail = () => {
    axios
      .get(
        JOB_DETAIL +
          "/" +
          (location?.state?.record ? location.state.record._id : openingId),
        {
          headers: { Authorization: users.token },
        }
      )
      .then((res) => {
        console.log(res.data.data[0]);
        setOpeningDetails(res.data.data[0]);
        getJobAssignmentDetails(res.data.data[0].opening_id);

        setOpeningUpdate(true);
      })
      .catch((error) => {});
  };

  const getJobAssignmentDetails = (id) => {
    axios
      .post(
        JOB_ASSIGNMENT_DETAILS,
        {
          created_by: users.user._id,
          // notforbdm: 1,
          opening_id: id,
        },
        {
          headers: { Authorization: users.token },
        }
      )
      .then((res) => {
        const data = res.data.data;
        if (data.length > 0) {
          let assigned_rec_arr = [];
          let assigned_free_arr = [];
          let assigned_bdm = [];
          data.forEach((element) => {
            assigned_rec_arr.push(...element.assigned_recruiter);
            assigned_free_arr.push(...element.assigned_freelancer);
            assigned_bdm.push(...element.assigned_bdm);
          });
          setSelectedRecruter(assigned_rec_arr);
          setSelectedFreelancer(assigned_free_arr);
          setSelectedBDM(assigned_bdm);
        }

        // getJobAssignmentDetailsWithoutBdm(id);
      })
      .catch((error) => {});
  };

	const updateSubmissionCount = () => {
		if (openingDetails) {
			axios
				.post(
					BDM_UPDATE_SUBMISSION_COUNT,
					{
						bdm_id: users.user._id,
						opening_id: openingDetails.opening_id,
					},
					{
						headers: { Authorization: users.token },
					}
				)
				.then((res) => {})
				.catch((error) => {});
		}
	};
	// console.log("company_id", openingDetails);
	const RetrieveBack = (selecterIds, status) => {
		let candidate_submission_by_bdm = [];
		selecterIds.map((item) => {
			const object = {
				opening_id: openingDetails.opening_id,
				bdm_id: users.user._id,
				_id: item,
				company_id: openingDetails.account_name[0]._id,
			};
			candidate_submission_by_bdm.push(object);
		});

    let param = {
      candidate_submission_by_bdm: candidate_submission_by_bdm,
    };

    if (selecterIds.length > 0) {
      setLoading(true);
      axios
        .post(CANDIDATE_SUBMISSION_RE_SUBMISSION, param, {
          headers: { Authorization: users.token },
        })
        .then((res) => {
          if (!res.data.error) {
            setSuccess(true);
            setMsgSuccess(res.data.message);
            setLoading(false);
            setError(false);
            getOpeningDetail();
            setDefaultState();
          }
        })
        .catch((error) => {
          setSuccess(false);
          setLoading(false);
          setError(true);
          let errorMessage = "";
          {
            error.response.data &&
              Object.entries(error.response.data.errors).map(([key, value]) => {
                return (errorMessage += value + ", ");
              });
          }
          setmsgError(errorMessage);
          setDefaultState();
        });
    } else {
      setSuccess(false);
      setLoading(false);
      setError(true);
      const errorMessage = "please select candidate";
      setmsgError(errorMessage);
      setDefaultState();
    }
  };

  const onSubmit = (selecterIds, status) => {
    let candidate_submission_by_bdm = [];
    selecterIds.map((item) => {
      const object = {
        opening_id: openingDetails.opening_id,
        _id: item,
        bdm_id: users.user._id,
        company_id: openingDetails.account_name[0]._id,
        submission_status: status,
        candidate_select_by_bdm: status === "submit" ? "1" : "0",
      };
      candidate_submission_by_bdm.push(object);
    });

    let param = {
      candidate_submission_by_bdm: candidate_submission_by_bdm,
    };

    if (selecterIds.length > 0) {
      setLoading(true);
      axios
        .post(SUBMISSION_BY_BDM, param, {
          headers: { Authorization: users.token },
        })
        .then((res) => {
          if (!res.data.error) {
            setSuccess(true);
            setMsgSuccess(res.data.message);
            setLoading(false);
            setError(false);
            getOpeningDetail();
            setDefaultState();
          }
        })
        .catch((error) => {
          setSuccess(false);
          setLoading(false);
          setError(true);
          let errorMessage = "";
          {
            error.response.data &&
              Object.entries(error.response.data.errors).map(([key, value]) => {
                return (errorMessage += value + ", ");
              });
          }
          setmsgError(errorMessage);
          setDefaultState();
        });
    } else {
      setSuccess(false);
      setLoading(false);
      setError(true);
      const errorMessage = "please select candidate";
      setmsgError(errorMessage);
      setDefaultState();
    }
  };

  const showResult = (result) => {
    result
      .then((res) => {
        if (!res.data.error) {
          setSuccess(true);
          setMsgSuccess(res.data.message);
          setLoading(false);
          setError(false);
          getOpeningDetail();
          setDefaultState();
        }
      })
      .catch((error) => {
        setSuccess(false);
        setLoading(false);
        setError(true);
        let errorMessage = "";
        {
          error.response.data &&
            Object.entries(error.response.data.errors).map(([key, value]) => {
              return (errorMessage += value + ", ");
            });
        }
        setmsgError(errorMessage);
        setDefaultState();
      });
  };

  const setDefaultState = () => {
    setTimeout(() => {
      setSuccess(false);
      setLoading(false);
      setError(false);
      setOpeningUpdate(false);
      setMsgSuccess("");
      setmsgError("");
    }, 3000);
  };

  const objectRec = openingDetails
    ? {
        opening_id: openingDetails ? openingDetails.opening_id : "",
        // recruiter_id: openingDetails
        //   ? openingDetails.assigned_recruiter.length > 0
        //     ? openingDetails.assigned_recruiter.map(item => item._id)
        //     : ""
        //   : "",
        bdm_id: users.user._id,
        // freelancer_recruiter_id: openingDetails
        //   ? openingDetails.assigned_freelancer.length > 0
        //     ? openingDetails.assigned_freelancer.map(item => item._id)
        //     : ""
        //   : "",
        company_id: openingDetails.account_name[0]._id,
      }
    : undefined;

  const objectBDM = openingDetails
    ? {
        opening_id: openingDetails ? openingDetails.opening_id : "",
        recruiter_id: "",
        bdm_id: users.user._id,
      }
    : undefined;

  return (
    <div>
      <Row gutter={24}>
        <Col span={14}>
          <OpeningDetailUi
            openingDetails={openingDetails}
            goBack={props.history.goBack}
          />
          <br />
          <ActivityLogUI
            props={props}
            param={openingDetails}
            isActivityLogs={false}
          />
        </Col>

        <Col span={10}>
          <ActivityLogUI
            props={props}
            param={openingDetails}
            isActivityLogs={true}
          />
          <br />

          <AssignJob
            openingDetails={openingDetails}
            setSuccess={(status, msg) => {
              setSuccess(status);
              setMsgSuccess(msg);
              setDefaultState();
              getOpeningDetail();
              getJobAssignmentDetails(openingDetails.opening_id);
            }}
            setError={(status, msg) => {
              setError(status);
              setmsgError(msg);
              setDefaultState();
            }}
            recruiters={selectedRecruter}
            freelancers={selectedFreelancer}
            bdms={selectedBDM}
          />
          <br />

			 		<BooleanString
			   jobId={openingDetails._id}
			   description={openingDetails?.job_description}
			   category={openingDetails?.category?.[0]?.name ?? ""}
			   title={openingDetails?.opening_title}
			   city={openingDetails?.city?.[0]?.city ?? ""}
			   state={openingDetails?.state?.[0]?.state ?? ""}
			   country={openingDetails?.country}
			   required_skills={openingDetails?.required_skills}
			 />
				</Col>
			</Row>
			<br />
			<Row gutter={24}>
				{/* {selectedRecruter.length > 0 ||
            selectedFreelancer.length > 0 ? ( */}
        <>
          <Col span={12}>
            <Card>
              <Tabs defaultActiveKey="1">
                <TabPane tab="Submited" key="1">
                  <CandidatesbyMe
                    object={objectBDM}
                    isDataUpdate={isOpeningUpdate}
                    opening_id={openingDetails}
                  />
                </TabPane>
                <TabPane tab="On Hold" key="2">
                  <OnHoldCandidates
                    onSubmit={RetrieveBack}
                    object={objectBDM}
                    isDataUpdate={true}
                    loading={loading}
                    opening_details={openingDetails}
                  />
                </TabPane>
                <TabPane tab="Rejected" key="3">
                  <RejectedCandidate
                    onSubmit={RetrieveBack}
                    object={objectBDM}
                    isDataUpdate={true}
                    loading={loading}
                    opening_details={openingDetails}
                  />
                </TabPane>
                <TabPane tab="Other Submissions" key="4">
                  <CandidatesbyOtherBDM
                    opening_id={openingDetails.opening_id}
                    isDataUpdate={true}
                  />
                </TabPane>
              </Tabs>
            </Card>
          </Col>
          <Col span={12}>
            <Col>
              <CandidatesbyRecruiter
                onSubmit={onSubmit}
                object={objectRec}
                isDataUpdate={isOpeningUpdate}
                loading={loading}
                opening_details={openingDetails}
              />
            </Col>

            <Col className="mt-10">
              <CandidatesbyBDM
                onSubmit={onSubmit}
                object={objectRec}
                isDataUpdate={isOpeningUpdate}
                loading={loading}
                opening_details={openingDetails}
                result={showResult}
              />
            </Col>
          </Col>
        </>
      </Row>

      <Row gutter={24}>
        <Col span={24}>{showError(success, msgSuccess, error, msgError)}</Col>
      </Row>
    </div>
  );
}
