import React, { useState } from "react";
import { Modal, Typography, Button, Avatar, Input, message } from "antd";
import axios from "axios";
import {
  COPILOT_GET_EMAIL_CONVO,
  SEND_EMAIL_MESSAGE_TO_CANDIDATE,
} from "../../../ApiUrl";
import { useSelector } from "react-redux";
import { OpenAIFilled, SendOutlined, UserOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";

const EmailConversationModal = ({ sourcedCandidateId, visible, onClose }) => {
  const [conversation, setConversation] = useState([]);
  const [loading, setLoading] = useState(false);
  const [emailMessage, setMessage] = useState(""); // State to manage input message
  const users = useSelector(({ users }) => users);

  function cleanHTML(html) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const elements = doc.body.querySelectorAll("*");
    elements.forEach((el) => {
      el.removeAttribute("style");
      el.removeAttribute("class");
      el.removeAttribute("id");
      if (
        ![
          "P",
          "H1",
          "H2",
          "H3",
          "H4",
          "H5",
          "H6",
          "UL",
          "OL",
          "LI",
          "DIV",
          "SPAN",
        ].includes(el.tagName)
      ) {
        el.replaceWith(document.createTextNode(el.textContent || ""));
      }
    });
    return doc.body.innerHTML.trim();
  }

  const fetchConversation = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(
        `${COPILOT_GET_EMAIL_CONVO}/${sourcedCandidateId}`,
        {
          headers: { Authorization: users.token },
        }
      );
      setConversation(data.data);
    } catch (error) {
      console.error("Error fetching conversation:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSend = async () => {
    try {
      if (emailMessage.trim()) {
        message.loading("Sending message...");
        const response = await axios.post(
          `${SEND_EMAIL_MESSAGE_TO_CANDIDATE}`,
          {
            candidateId: sourcedCandidateId,
            message: emailMessage,
          },
          {
            headers: { Authorization: users.token },
          }
        );
        if (response.status === 200) {
          await fetchConversation();
          message.success("Message sent successfully!");
          setMessage("");
        }
      }
    } catch (error) {
      console.log("Error sending message:", error);
    }
  };

  React.useEffect(() => {
    if (visible) {
      fetchConversation();
    }
  }, [visible]);

  return (
    <Modal
      title="Email Conversation"
      visible={visible}
      onCancel={onClose}
      footer={[
        <Button key="close" onClick={onClose}>
          Close
        </Button>,
      ]}
      width={800}
      bodyStyle={{ padding: "20px", backgroundColor: "#f9f9f9" }}
    >
      {loading ? (
        <Typography.Text>Loading conversation...</Typography.Text>
      ) : (
        <div style={{ maxHeight: "500px", overflowY: "auto" }}>
          {conversation.map((item, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                alignItems: "flex-start",
                marginBottom: "15px",
                flexDirection:
                  item.sender === "ai" || item.sender === "recruiter"
                    ? "row-reverse"
                    : "row",
              }}
            >
              <Avatar
                size={40}
                style={{
                  backgroundColor:
                    item.sender === "ai"
                      ? "#1890ff"
                      : item.sender === "recruiter"
                      ? "#52c41a"
                      : "#f5f5f5",
                  marginLeft:
                    item.sender === "ai" || item.sender === "recruiter"
                      ? "10px"
                      : "0",
                  marginRight:
                    item.sender !== "ai" && item.sender !== "recruiter"
                      ? "10px"
                      : "0",
                }}
                icon={
                  item.sender === "ai" ? (
                    <OpenAIFilled />
                  ) : item.sender === "recruiter" ? (
                    <UserOutlined />
                  ) : (
                    <UserOutlined />
                  )
                }
              />
              <div
                style={{
                  backgroundColor:
                    item.sender === "ai"
                      ? "#e6f7ff"
                      : item.sender === "recruiter"
                      ? "#f6ffed"
                      : "#f5f5f5",
                  borderRadius: "12px",
                  padding: "10px 15px",
                  maxWidth: "70%",
                  boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Typography.Paragraph style={{ margin: 0 }}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: cleanHTML(item.content),
                    }}
                  />
                </Typography.Paragraph>
                <Typography.Text
                  style={{
                    fontSize: "12px",
                    color: "#888",
                    display: "block",
                    marginTop: "5px",
                    textAlign: "right",
                  }}
                >
                  {new Date(item.created_at).toLocaleString()}
                </Typography.Text>
              </div>
            </div>
          ))}
        </div>
      )}
      <div style={{ marginTop: "15px", display: "flex", gap: "10px" }}>
        <TextArea
          value={emailMessage}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Type a message..."
        />
        <Button type="primary" onClick={handleSend} icon={<SendOutlined />} />
      </div>
    </Modal>
  );
};

export default EmailConversationModal;
