import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Button, Form, Input, message, Modal, Typography } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { EMAIL_TEMPLATES_CREATE } from "../../../ApiUrl";
import axios from "axios";

const CreateEmailTemplate = ({ visible, onClose }) => {
  const [emailTemplateForm] = Form.useForm();

  const [editor, setEditor] = useState();

  const users = useSelector(({ users }) => users);

  const handleSaveEmailTemplate = (values) => {
    message.loading("creating template...", 0);
    const params = {
      user_id: users.user._id,
      email_type: "ai-copilot-recruitment",
      content: values.content,
      template_name: values.template_name,
      subject: values.subject,
    };
    axios
      .post(EMAIL_TEMPLATES_CREATE, params, {
        headers: { Authorization: users.token },
      })
      .then((res) => {
        message.destroy();
        message.success("Sucessfully created Email Template", 0);
      })
      .catch((error) => {});
    emailTemplateForm.resetFields();
    onClose();
    message.destroy();
  };

  const appendVariable = (tag) => {
    if (!editor) return; // Ensure the CKEditor instance exists

    editor.model.change((writer) => {
      const selection = editor.model.document.selection;

      // Check if there's a range in the current selection
      if (selection.isCollapsed) {
        // Insert the tag at the cursor position
        const position = selection.getFirstPosition();
        writer.insertText(tag, position);
      } else {
        // Replace the selected text with the tag
        const range = selection.getFirstRange();
        writer.insertText(tag, range);
        writer.setSelection(writer.createPositionAfter(range.end.nodeAfter));
      }
    });

    // Focus the editor
    editor.editing.view.focus();
  };
  return (
    <Modal
      title="Create Email Template"
      visible={visible}
      onCancel={() => {
        onClose();
      }}
      footer={null}
      width={800}
      bodyStyle={{ padding: "24px 32px" }}
    >
      <Form
        form={emailTemplateForm}
        layout="vertical"
        onFinish={handleSaveEmailTemplate}
      >
        {/* Template Subject */}
        <Form.Item
          name="template_name"
          label={<strong>Template Name</strong>}
          rules={[
            {
              required: true,
              message: "Please enter the template name",
            },
          ]}
        >
          <Input placeholder="Enter email subject" />
        </Form.Item>
        {/* Template Subject */}
        <Form.Item
          name="subject"
          label={<strong>Subject</strong>}
          rules={[
            {
              required: true,
              message: "Please enter the email subject",
            },
          ]}
        >
          <Input placeholder="Enter email subject" />
        </Form.Item>

        {/* Variable Options */}
        <Typography.Text strong>Add Variables:</Typography.Text>
        <div style={{ marginBottom: "10px" }}>
          {[
            { label: "Opening Title", tag: "{{OpeningTitle}}" },
            { label: "Candidate Name", tag: "{{CandidateName}}" },
            { label: "Short Description", tag: "{{ShortDescription}}" },
          ].map((variable) => (
            <Button
              key={variable.tag}
              type="default"
              size="small"
              style={{ marginRight: "8px", marginBottom: "8px" }}
              onClick={() => appendVariable(variable.tag)}
            >
              {variable.label}
            </Button>
          ))}
        </div>

        <Form.Item
          name="content"
          label={<strong>Content</strong>}
          rules={[
            {
              required: true,
              message: "Please enter the email content.",
            },
          ]}
        >
          <CKEditor
            editor={ClassicEditor}
            data={emailTemplateForm.getFieldValue("content")} // Initialize with current form value
            onChange={(event, editor) => {
              const data = editor.getData();
              emailTemplateForm.setFieldsValue({ content: data });
              setEditor(editor); // Save the editor instance
            }}
          />
        </Form.Item>

        {/* Save Button */}
        <Form.Item>
          <Button type="primary" htmlType="submit" block>
            Save Template
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateEmailTemplate;
