import React, { useEffect, useState } from "react";
import {
  Table,
  Card,
  Typography,
  Spin,
  message,
  Modal,
  InputNumber,
  Button,
} from "antd";
import axios from "axios";
import {
  COPILOT_EDIT_CAMPAIGN_REQUEST,
  COPILOT_GET_USER_CAMPAIGNS,
} from "../../../ApiUrl";
import { useSelector } from "react-redux";
import { Settings } from "@material-ui/icons";

const { Text } = Typography;

const SourcingSetting = () => {
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [localSourcing, setLocalSourcing] = useState(0);
  const [monsterSourcing, setMonsterSourcing] = useState(0);

  const users = useSelector(({ users }) => users);

  useEffect(() => {
    const fetchCampaigns = async () => {
      try {
        setLoading(true);
        const response = await axios.get(COPILOT_GET_USER_CAMPAIGNS, {
          headers: { Authorization: users.token },
        });
        setCampaigns(response.data.data); // Assuming response.data.data is an array of campaigns
      } catch (error) {
        message.error("Failed to load campaigns. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchCampaigns();
  }, []);

  // Handle settings button click
  const handleSettingsClick = (campaign) => {
    setSelectedCampaign(campaign);
    setLocalSourcing(campaign?.number_of_source_per_platforms?.local || 0);
    setMonsterSourcing(campaign?.number_of_source_per_platforms?.monster || 0);
    setIsModalVisible(true);
  };

  // Handle modal save
  const handleSaveSettings = async () => {
    const updatedCampaigns = campaigns.map((campaign) =>
      campaign._id === selectedCampaign._id
        ? {
            ...campaign,
            number_of_source_per_platforms: {
              ...campaign.number_of_source_per_platforms,
              local: localSourcing,
              monster: monsterSourcing,
            },
          }
        : campaign
    );
    const payload = {
      number_of_source_per_platforms: {
        local: localSourcing,
        monster: monsterSourcing,
      },
    };
    await axios.post(
      `${COPILOT_EDIT_CAMPAIGN_REQUEST}/${selectedCampaign._id}`,
      payload,
      {
        headers: { Authorization: users.token },
      }
    );
    setCampaigns(updatedCampaigns);
    message.success("Settings updated succesfully.");
    setIsModalVisible(false);
  };

  // Define table columns
  const columns = [
    {
      title: "Name",
      dataIndex: "campaign_name",
      key: "campaign_name",
    },
    {
      title: "Opening Title",
      dataIndex: "type",
      key: "type",
      render: (code, row) => row?.opening?.opening_title,
    },
    {
      title: "Total Sourcing (Local)",
      dataIndex: "type",
      key: "type",
      render: (code, row) => row?.number_of_source_per_platforms?.local,
    },
    {
      title: "Total Sourcing (Monster)",
      dataIndex: "type",
      key: "type",
      render: (code, row) => row?.number_of_source_per_platforms?.monster,
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Button
          icon={<Settings />}
          type="link"
          onClick={() => handleSettingsClick(record)}
        />
      ),
    },
  ];

  if (loading) {
    return (
      <Spin
        tip="Loading campaigns..."
        style={{ display: "block", textAlign: "center", marginTop: "20px" }}
      />
    );
  }

  return (
    <>
      <Card title="Campaigns Listing" style={{ margin: "20px" }}>
        {campaigns.length === 0 ? (
          <Text>No campaigns found.</Text>
        ) : (
          <Table
            dataSource={campaigns}
            columns={columns}
            rowKey={(record) => record._id}
            pagination={{ pageSize: 10 }}
          />
        )}
      </Card>

      <Modal
        title={`Settings`}
        visible={isModalVisible}
        onOk={handleSaveSettings}
        onCancel={() => setIsModalVisible(false)}
        okText="Save"
        cancelText="Cancel"
      >
        <div style={{ marginBottom: "16px" }}>
          <Text>Local Sourcing:</Text>
          <InputNumber
            min={0}
            value={localSourcing}
            onChange={(value) => setLocalSourcing(value)}
            style={{ width: "100%" }}
          />
        </div>
        <div>
          <Text>Monster Sourcing:</Text>
          <InputNumber
            min={0}
            value={monsterSourcing}
            onChange={(value) => setMonsterSourcing(value)}
            style={{ width: "100%" }}
          />
        </div>
      </Modal>
    </>
  );
};

export default SourcingSetting;
