import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Calendar,
  Row,
  Col,
  TimePicker,
  Card,
  message,
  Input,
  Space,
} from "antd";
import {
  PlusOutlined,
  DeleteOutlined,
  LeftOutlined,
  RightOutlined,
  EditOutlined,
} from "@ant-design/icons";
import moment from "moment";
import axios from "axios";
import {
  COPILOT_DELETE_DATE_SPECIFIC_SLOTS,
  COPILOT_GET_DATE_SPECIFIC_SLOTS,
  COPILOT_TIME_SLOT_SELECTION,
} from "../../../ApiUrl";
import { useSelector } from "react-redux";

const DateSpecificSlots = () => {
  const defaultTime = moment("00:00", "HH:mm");
  const users = useSelector(({ users }) => users);
  const [sharedSlots, setSharedSlots] = useState([
    { start: moment("09:00", "HH:mm"), end: moment("17:00", "HH:mm") },
  ]);
  const [selectedDates, setSelectedDates] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentDate, setCurrentDate] = useState(moment());
  const [fetchedSlots, setFetchedSlots] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false); // New state for edit mode
  const [editGroupData, setEditGroupData] = useState(null); // New state for selected group data

  // Fetch date-specific slots
  useEffect(() => {
    fetchDateSpecificSlots();
  }, [users.token]);

  function fetchDateSpecificSlots() {
    axios
      .get(COPILOT_GET_DATE_SPECIFIC_SLOTS, {
        headers: { Authorization: users.token },
      })
      .then((response) => {
        const slots = response.data.data;
        setFetchedSlots(groupSlotsByDate(slots));
      })
      .catch((error) => message.error(error.message));
  }

  const handleRemoveSharedSlot = (index) => {
    setSharedSlots((prev) => prev.filter((_, i) => i !== index));
  };

  const handleSharedTimeChange = (index, type, value) => {
    setSharedSlots((prev) => {
      const updated = [...prev];
      updated[index][type] = value;
      return updated;
    });
  };

  const handleAddSharedSlot = () => {
    setSharedSlots((prev) => {
      if (prev.length === 0) {
        return [
          ...prev,
          {
            start: moment("09:00 AM", "hh:mm A"),
            end: moment("05:00 PM", "hh:mm A"),
          },
        ];
      } else {
        const lastSlot = moment(prev[prev.length - 1].end, "hh:mm A");
        const nextStartTime = lastSlot.clone().add(1, "hour");
        const nextEndTime = nextStartTime.clone().add(1, "hour");

        return [
          ...prev,
          {
            start: nextStartTime,
            end: nextEndTime,
          },
        ];
      }
    });
  };
  const onMonthChange = (direction) => {
    setCurrentDate(
      direction === "prev"
        ? moment(currentDate).subtract(1, "month")
        : moment(currentDate).add(1, "month")
    );
  };

  const groupSlotsByDate = (slots) => {
    let groupedSlots = []; // Holds all the grouped slots
    let currentGroup = null; // The current group that we're building

    // Sort the slots by date to ensure they're in the correct order
    slots.sort((a, b) => new Date(a.date) - new Date(b.date));

    slots.forEach((slot, index) => {
      const date = moment(slot.date).format("YYYY-MM-DD");
      const timeslots = slot.timeslots; // List of time slots for the current date

      if (
        currentGroup && // If there is a current group
        currentGroup.timeslots.length === timeslots.length && // Check if time slots count match
        currentGroup.timeslots.every(
          (ts, i) =>
            ts.start === timeslots[i].start && ts.end === timeslots[i].end
        ) && // Check if each timeslot matches
        moment(date).diff(
          moment(currentGroup.dates[currentGroup.dates.length - 1]),
          "days"
        ) === 1
      ) {
        // Check if the current date is exactly one day after the last date in the group
        currentGroup.dates.push(date);
      } else {
        // If the timeslots differ or dates are not consecutive, push the current group and start a new one
        if (currentGroup) {
          groupedSlots.push(currentGroup);
        }
        currentGroup = {
          dates: [date], // Start with the current date
          timeslots: timeslots, // Store the timeslots from the first date of the group
        };
      }
    });

    // After the loop, if there is a group left, push it to the groupedSlots
    if (currentGroup) {
      groupedSlots.push(currentGroup);
    }

    return groupedSlots;
  };

  const toggleDateSelection = (date) => {
    const formattedDate = date.format("YYYY-MM-DD");
    setSelectedDates((prev) =>
      prev.includes(formattedDate)
        ? prev.filter((d) => d !== formattedDate)
        : [...prev, formattedDate]
    );
  };

  const handleDeleteGroup = (groupDates) => {
    axios
      .post(
        COPILOT_DELETE_DATE_SPECIFIC_SLOTS,
        { dates: groupDates },
        {
          headers: { Authorization: users.token },
        }
      )
      .then(() => {
        fetchDateSpecificSlots();
        message.success("Slots Removed Successfully!");
      })
      .catch(() => message.error("Failed to remove slots."));
  };

  const handleApplyDateSlots = () => {
    const dataToSend = {
      datesAndSlots: selectedDates.map((date) => ({
        date,
        slots: sharedSlots.map((slot) => ({
          start: slot.start.format("HH:mm"),
          end: slot.end.format("HH:mm"),
        })),
      })),
    };

    axios
      .post(COPILOT_TIME_SLOT_SELECTION, dataToSend, {
        headers: { Authorization: users.token },
      })
      .then(() => {
        fetchDateSpecificSlots();
        message.success("Slots applied successfully!");
      })
      .catch(() => message.error("Failed to apply slots."));
    setIsModalOpen(false);
    setIsEditMode(false); // Reset edit mode state
    setEditGroupData(null); // Reset edit group data
  };

 

  const handleRemoveSlot = (index) => {
    const updatedSlots = sharedSlots.filter((_, i) => i !== index);
    setSharedSlots(updatedSlots);
  };

  const handleEditGroup = (group) => {
    setIsEditMode(true);
    setEditGroupData(group); // Store the data of the group being edited
    setSelectedDates(group.dates); // Pre-select the dates for this group
    const formattedSlots = group.timeslots.map((slot) => ({
      start: moment(slot.start, "HH:mm"),
      end: moment(slot.end, "HH:mm"),
    }));

    // Pre-load the slots for this group
    setSharedSlots(formattedSlots);

    setCurrentDate(moment(group.dates[0])); // Set the current date to the first date in the group
    setIsModalOpen(true); // Open the modal for editing
  };

  const formatDateRange = (dates) => {
    if (dates.length === 1) {
      return moment(dates[0]).format("MMM D, YYYY");
    }
    const startDate = moment(dates[0]).format("MMM D, YYYY");
    const endDate = moment(dates[dates.length - 1]).format("MMM D, YYYY");
    return `${startDate} – ${endDate}`;
  };

  return (
    <Card style={{ flex: 1, padding: "20px" }}>
      <p style={{ margin: 0, fontWeight: "bold" }}>Date-specific hours</p>
      <p style={{ margin: "4px 0" }}>
        Override your availability for specific dates when your hours differ
        from your regular weekly hours.
      </p>
      <Button
        type="dashed"
        onClick={() => {
          setIsModalOpen(true);
          setSelectedDates([]);
          setSharedSlots([
            { start: moment("09:00", "HH:mm"), end: moment("17:00", "HH:mm") },
          ]);
        }}
      >
        Add Date-Specific Slots
      </Button>
      <Modal
        title={isEditMode ? "Edit Date-Specific Slots" : "Date-Specific Slots"}
        visible={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
          setIsEditMode(false);
          setEditGroupData(null);
        }}
        onOk={handleApplyDateSlots}
      >
        <Calendar
          fullscreen={false}
          value={currentDate}
          onPanelChange={(date) => setCurrentDate(date)}
          onSelect={(date) => {
            const today = moment().startOf("day");
            if (date.isSameOrAfter(today)) {
              toggleDateSelection(date);
            }
          }}
          headerRender={() => (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0 16px",
                alignItems: "center",
              }}
            >
              <LeftOutlined
                onClick={() => onMonthChange("prev")}
                style={{ cursor: "pointer" }}
              />
              <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                {currentDate.format("MMMM YYYY")}
              </span>
              <RightOutlined
                onClick={() => onMonthChange("next")}
                style={{ cursor: "pointer" }}
              />
            </div>
          )}
          dateFullCellRender={(date) => {
            const today = moment().startOf("day");
            const formattedDate = date.format("YYYY-MM-DD");
            const isPastDate = date.isBefore(today);
            const isSelected = selectedDates.includes(formattedDate);
            return (
              <div
                style={{
                  width: 60,
                  height: 60,
                  padding: 8,
                  background: isSelected ? "#1890ff" : undefined,
                  color: isSelected
                    ? "#FFFFFF"
                    : isPastDate
                    ? "#d9d9d9"
                    : "#000000",
                  borderRadius: "50%",
                  cursor: isPastDate ? "not-allowed" : "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  opacity: isPastDate ? 0.8 : 1,
                }}
              >
                {date.date()}
              </div>
            );
          }}
        />
        <Row gutter={16}>
          <Col span={24}>
            <p style={{ fontWeight: "bold", marginBottom: "8px" }}>
              Select Time Slots
            </p>
            {sharedSlots.map((slot, index) => (
              <Row key={index} style={{ marginBottom: "8px" }}>
                <Col span={10}>
                  <TimePicker
                    value={slot.start}
                    onChange={(value) =>
                      handleSharedTimeChange(index, "start", value)
                    }
                    format="HH:mm"
                    placeholder="Start Time"
                  />
                </Col>
                <Col span={10}>
                  <TimePicker
                    value={slot.end}
                    onChange={(value) =>
                      handleSharedTimeChange(index, "end", value)
                    }
                    format="HH:mm"
                    placeholder="End Time"
                  />
                </Col>
                <Col span={4}>
                  {/* <Button
                    icon={<EditOutlined />}
                    onClick={() => handleEditSlot(index)}
                    size="small"
                    style={{ marginRight: 4 }}
                  /> */}
                  <Button
                    icon={<DeleteOutlined />}
                    onClick={() => handleRemoveSlot(index)}
                    size="small"
                    danger
                  />
                </Col>
              </Row>
            ))}
            <Button
              icon={<PlusOutlined />}
              onClick={handleAddSharedSlot}
              type="dashed"
              block
            >
              Add Time Slot
            </Button>
          </Col>
        </Row>
      </Modal>
      {fetchedSlots.length > 0 && (
        <Row gutter={16}>
          {fetchedSlots.map((group, index) => (
            <Col key={index} span={24}>
              <Card
                title={formatDateRange(group.dates)}
                extra={
                  <>
                    <Button
                      icon={<EditOutlined />}
                      onClick={() => handleEditGroup(group)}
                      size="small"
                      type="primary"
                    >
                      Edit
                    </Button>

                    <Button
                      icon={<DeleteOutlined />}
                      danger
                      onClick={() => handleDeleteGroup(group.dates)}
                    >
                      Delete
                    </Button>
                  </>
                }
              >
                <p>Timeslots:</p>
                {group.timeslots.map((slot, idx) => (
                  <p key={idx}>{`${slot.start} - ${slot.end}`}</p>
                ))}
              </Card>
            </Col>
          ))}
        </Row>
      )}
    </Card>
  );
};

export default DateSpecificSlots;
