import React, { useEffect, useRef, useState } from "react";
import {
  Form,
  Col,
  Row,
  Card,
  Select,
  Button,
  Typography,
  Input,
  message,
  Spin,
  Modal,
  Space,
  Checkbox,
  TimePicker,
  InputNumber,
  DatePicker,
} from "antd";
import axios from "axios";
import {
  JOB_DETAIL,
  ASSIGN_JOB,
  JOB_BDM_LIST,
  JOB_ASSIGNMENT_DETAILS,
  JOB_RECRUITER_LIST,
  JOB_UPDATE,
  EMAIL_TEMPLATES_BY_TYPE_DETAIL,
  COPILOT_CREATE_CAMPAIGN_REQUEST,
  EMAIL_TEMPLATES_CREATE,
  EMAIL_TEMPLATES_USER_BY_TYPE,
  COPILOT_GET_CAMPAIGN_REQUESTS,
  COPILOT_EDIT_CAMPAIGN_REQUEST,
} from "../../../ApiUrl";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import ActivityLogUI from "../ActivityLog";
import { showError } from "../../pages/utils/helpers";
import OpeningDetailUi from "./OpeningDetailUI";
import { logoutHandler } from "../../../others/logout";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { requestHeader } from "../../../others/header";
import BooleanString from "./BooleanString";
import {
  FacebookOutlined,
  LinkedinOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import {
  optionEmploymentType,
  optionsInterviewType,
} from "../../pages/constant/constant";
import { RoomOutlined } from "@material-ui/icons";
import moment from "moment";
import EmailTempalte from "../messages/EmailTemplates";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import { ClassicEditor } from "@ckeditor/ckeditor5-build-classic";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Title from "antd/lib/typography/Title";

const { Option } = Select;
const { Text } = Typography;
const FormItem = Form.Item;
const DOMAIN_URL = process.env.REACT_APP_DOMAIN_URL;

export default function OpeningsDetail(props) {
  const [openingDetails, setOpeningDetails] = useState("");
  const [allBDM, setAllBDM] = useState([]);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msgSuccess, setMsgSuccess] = useState("");
  const [msgError, setmsgError] = useState("");
  const [selectedBDM, setSelectedBDM] = useState([]);
  const [recruiterList, setAllRecruiterList] = useState([]);
  const [selectedRecruiter, setSelectedRecruiter] = useState([]);
  const [booleanData, setBooleanData] = useState([]);
  const [isEmbedModalOpen, setIsEmbedModalOpen] = useState(false);
  const [iframeCode, setIframeCode] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [editor, setEditor] = useState();
  const [campaign, setCampaign] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  const users = useSelector(({ users }) => users);
  let location = useLocation();
  const history = useHistory();
  const { openingId } = useParams();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isCampaignModalOpen, setIsisCampaignModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [isEmailTemplateModalOpen, setIsEmailTemplateModalOpen] = useState(
    false
  );
  const [emailTemplateForm] = Form.useForm();
  const contentInputRef = useRef(null);

  const [predefinedTemplates, setPredefinedTemplates] = useState([]);

  const appendVariable = (tag) => {
    if (!editor) return; // Ensure the CKEditor instance exists

    editor.model.change((writer) => {
      const selection = editor.model.document.selection;

      // Check if there's a range in the current selection
      if (selection.isCollapsed) {
        // Insert the tag at the cursor position
        const position = selection.getFirstPosition();
        writer.insertText(tag, position);
      } else {
        // Replace the selected text with the tag
        const range = selection.getFirstRange();
        writer.insertText(tag, range);
        writer.setSelection(writer.createPositionAfter(range.end.nodeAfter));
      }
    });

    // Focus the editor
    editor.editing.view.focus();
  };

  const fetchCampaign = async () => {
    try {
      const openingId = location.state.record._id; // Replace with dynamic value if needed
      const response = await axios.get(
        `${COPILOT_GET_CAMPAIGN_REQUESTS}/${openingId}`,
        {
          headers: { Authorization: users.token },
        }
      );
      setCampaign({ ...response.data.data[0] }); // Assuming the response contains one campaign
    } catch (error) {
      console.error("Error fetching campaign:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSaveEmailTemplate = (values) => {
    message.loading("creating template...", 0);
    const params = {
      user_id: users.user._id,
      email_type: "ai-copilot-recruitment",
      content: values.content,
      template_name: values.template_name,
      subject: values.subject,
    };
    setLoadingTemplate(true);
    axios
      .post(EMAIL_TEMPLATES_CREATE, params, {
        headers: { Authorization: users.token },
      })
      .then((res) => {
        message.destroy();
        message.success("Sucessfully created Email Template", 0);
      })
      .catch((error) => {});
    emailTemplateForm.resetFields();
    setIsEmailTemplateModalOpen(false);
    message.destroy();
  };

  const daysOptions = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const predefinedPlatforms = ["monster", "local"];

  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [templateContent, setTemplateContent] = useState("");
  const [loadingTemplate, setLoadingTemplate] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState("");

  // Fetch templates for a specific type
  const fetchTemplates = () => {
    setLoadingTemplate(true);

    axios
      .get(`${EMAIL_TEMPLATES_USER_BY_TYPE}?type=${"ai-copilot-recruitment"}`, {
        headers: { Authorization: users.token },
      })
      .then((res) => {
        const result = res.data.data || []; // Ensure it's an array
        const templates = result.map((item) => ({
          name: item.template_name,
          ...item,
        }));
        setPredefinedTemplates(templates); // Update the predefined templates with the response
        setLoadingTemplate(false);
      })
      .catch((error) => {
        console.error("Error fetching templates:", error);
        setPredefinedTemplates([]); // Reset templates on error
        setLoadingTemplate(false);
      });
  };

  useEffect(() => {
    console.log(campaign?.campaign_active_days_and_time?.time_range?.start); // Log the start time
    console.log(campaign?.campaign_active_days_and_time?.time_range?.end); // Log the end time
  }, [campaign]);

  // Handle template selection
  const handleTemplateSelection = (id) => {
    // Find the selected template from the fetched templates
    const selectedTemplate = predefinedTemplates?.find(
      (template) => template._id === id
    );
    console.log(selectedTemplate);

    if (selectedTemplate) {
      setTemplateContent(selectedTemplate.content); // Set the content
      setSelectedTemplate(selectedTemplate); // Update selected template
      setSelectedTemplateId(selectedTemplate._id); // Update selected template ID
    } else {
      // Handle case where the template is not found
      console.error("Selected template not found!");
      setTemplateContent("");
      setSelectedTemplate(null);
      setSelectedTemplateId(null);
    }
  };

  // const handleTemplateSelection = (templateType) => {
  //   console.log(templateType);
  //   const params = {
  //     user_id: users.user._id,
  //     email_type: templateType,
  //   };
  //   setLoadingTemplate(true);
  //   axios
  //     .post(EMAIL_TEMPLATES_BY_TYPE_DETAIL, params, {
  //       headers: { Authorization: users.token },
  //     })
  //     .then((res) => {
  //       console.log(res.data.data.content);
  //       setTemplateContent(res.data.data.content);
  //       setSelectedTemplate(res.data.data);
  //       setSelectedTemplateId(res.data.data._id);
  //       setLoadingTemplate(false);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching email template:", error);
  //       setTemplateContent("");
  //       setSelectedTemplate(null);
  //       setLoadingTemplate(false);
  //     });
  // };

  const showCampainModal = () => {
    fetchTemplates();
    setIsisCampaignModalOpen(true);
  };

  const handleCancelCampaignModal = () => {
    setIsisCampaignModalOpen(false);
    form.resetFields();
  };

  const handleSubmit = async (values) => {
    try {
      const payload = {
        ...values,
        number_of_source_per_platforms: values.platformNumbers,
        opening_id: openingDetails._id,
        mode: values.campaign_mode,
        emailTemplates: [values.template],
        campaign_active_days_and_time: {
          ...values.campaign_active_days_and_time,
          time_range: {
            start: values.campaign_active_days_and_time.time_range.start.format(
              "HH:mm"
            ),
            end: values.campaign_active_days_and_time.time_range.end.format(
              "HH:mm"
            ),
          },
        },
      };

      if (isEditing) {
        // Update existing campaign
        await axios.post(
          `${COPILOT_EDIT_CAMPAIGN_REQUEST}/${campaign._id}`,
          payload,
          {
            headers: { Authorization: users.token },
          }
        );
        message.success({
          content: "Campaign updated successfully.",
          duration: 3, // Message will disappear after 3 seconds
        });
      } else {
        await axios.post(COPILOT_CREATE_CAMPAIGN_REQUEST, payload, {
          headers: { Authorization: users.token },
        });
        message.success({
          content: "Campaign created successfully.",
          duration: 3, // Message will disappear after 3 seconds
        });
      }

      handleCancelCampaignModal();
      // fetchCampaign(); // Refresh campaign list
    } catch (error) {
      console.error(error);
      message.error({
        content: "An error occurred. Please try again.",
        duration: 3, // Message will disappear after 3 seconds
      });
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // Function to show the Embed Modal
  const showEmbedModal = () => {
    const code = generateIframe();
    setIframeCode(code); // Store the iframe code
    setIsEmbedModalOpen(true); // Open the modal
  };

  // Function to hide the Embed Modal
  const handleEmbedModalCancel = () => {
    setIsEmbedModalOpen(false);
  };

  // Function to copy the iframe code to clipboard
  const copyEmbedCode = () => {
    navigator.clipboard
      .writeText(iframeCode)
      .then(() => {
        message.success("Embed code copied to clipboard!");
      })
      .catch(() => {
        message.error("Failed to copy embed code.");
      });
  };

  const copyToClipboard = () => {
    const iframeCode = generateIframe();
    navigator.clipboard
      .writeText(iframeCode)
      .then(() => {
        message.success("Embed code copied to clipboard!");
      })
      .catch((err) => {
        message.error("Failed to copy embed code.");
      });
  };

  // Create the iframe embed code
  const generateIframe = () => {
    return `<iframe src="${DOMAIN_URL}/openings/${openingDetails?.opening_id}"
    width="600" height="400" frameborder="0" allowfullscreen>
    </iframe>`;
  };

  const handleShare = (platform) => {
    const jobTitle = "Job Title Here"; // Replace with job title from data
    const jobDescription =
      "Job Description Here Hello Description of Job is here."; // Replace with job description from data
    const jobUrl = `${DOMAIN_URL}/openings/${openingId}`; // Construct job URL dynamically

    const linkedInShareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
      jobUrl
    )}&title=${encodeURIComponent(jobTitle)}&summary=${encodeURIComponent(
      jobDescription
    )}&source=pk.indeed.com`;
    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      jobUrl
    )}`;
    if (platform === "facebook") {
      window.open(facebookShareUrl, "_blank");
    } else if (platform === "linkedin") {
      window.open(linkedInShareUrl, "_blank");
    }
  };

  useEffect(() => {
    getAllBDMList();
    getOpeningDetail();
    // fetchCampaign();
    getAllRecruterList();
    return () => {};
  }, []);

  const getAllRecruterList = () => {
    axios
      .get(JOB_RECRUITER_LIST, {
        headers: { Authorization: users.token },
      })
      .then((res) => {
        setAllRecruiterList(res.data.data);
        if (error?.response?.status == 401) {
          logoutHandler(history);
        }
      })
      .catch((error) => {});
  };

  const getOpeningDetail = () => {
    axios
      .get(JOB_DETAIL + "/" + openingId, {
        headers: { Authorization: users.token },
      })
      .then((res) => {
        setOpeningDetails(res.data.data[0]);
        setBooleanData(res.data.data[0].boolean_string);

        getJobAssignmentDetails(res.data.data[0].opening_id);
        let bdm =
          res.data.data[0].assigned_bdm ||
          res.data.data[0].account_name[0].assigned_to_bdm;

        setSelectedBDM(bdm.map((e) => e._id || e));
      })
      .catch((error) => {});
  };

  const getJobAssignmentDetails = (id) => {
    axios
      .post(
        JOB_ASSIGNMENT_DETAILS,
        {
          created_by: users.role != "admin" && users.user._id,
          opening_id: id,
        },
        {
          headers: { Authorization: users.token },
        }
      )
      .then((res) => {
        // console.log(res.data);
        setSelectedBDM(res.data.data[0].assigned_bdm);
        setSelectedRecruiter(res.data.data[0].assigned_recruiter);
      })
      .catch((error) => {});
  };

  const getAllBDMList = () => {
    axios
      .get(JOB_BDM_LIST, {
        headers: { Authorization: users.token },
      })
      .then((res) => {
        setAllBDM(res.data.data);
      })
      .catch((error) => {});
  };

  const handleAssignChange = (value) => {
    setSelectedBDM(value);
  };

  const postAssignJob = () => {
    const param = {
      opening_id: openingDetails.opening_id,
      bdm_id: selectedBDM,
      recruiter_id: selectedRecruiter,
      company_id: openingDetails.account_name[0]?._id,
      freelance_id: "",
      created_by: users.user?._id,
    };
    setLoading(true);
    axios
      .put(ASSIGN_JOB, param, {
        headers: { Authorization: users.token },
      })
      .then((res) => {
        setLoading(false);
        setSuccess(true);
        setSelectedBDM([]);
        setMsgSuccess(res.data.message);
        getOpeningDetail();
        setDefaultState();
      })
      .catch((error) => {
        setLoading(false);
        setError(true);
        let errorMessage = "";
        {
          error.response.data.keys.length > 0 &&
            Object.entries(error.response.data.errors).map(([key, value]) => {
              return (errorMessage += value + ", ");
            });
        }
        setmsgError(errorMessage);
        setDefaultState();
      });
  };

  const handleOpenCampaignModal = (campaign = null) => {
    fetchTemplates();
    setIsisCampaignModalOpen(true);

    // :
    // {time_range: {…}}
    // campaign_active_days_and_time.days
    // :
    // undefined
    // campaign_mode
    // :
    // undefined
    // campaign_name
    // :
    // "Hiring a Java Architect"
    // is_boolean_string_verified
    // :
    // true
    // platformNumbers
    // :
    // {monster: undefined, local: undefined}
    // template
    // :
    // undefined
    console.log(campaign);
    if (campaign) {
      form.setFieldsValue({
        ...campaign,
        platformNumbers: campaign.number_of_source_per_platforms,
        campaign_mode: campaign.mode,
        template: campaign.emailTemplates[0],
        campaign_active_days_and_time: {
          ...campaign.campaign_active_days_and_time,
          time_range: {
            start: campaign?.campaign_active_days_and_time?.time_range?.start
              ? moment(
                  campaign.campaign_active_days_and_time.time_range.start,
                  "HH:mm"
                )
              : null,
            end: campaign?.campaign_active_days_and_time?.time_range?.end
              ? moment(
                  campaign.campaign_active_days_and_time.time_range.end,
                  "HH:mm"
                )
              : null,
          },
        },
      });
      console.log(form.getFieldsValue());
      setIsEditing(true);
    } else {
      form.resetFields();
      setIsEditing(false);
    }
  };

  // const getJobAssignmentDetails = (id) => {
  //   axios
  //     .post(
  //       JOB_ASSIGNMENT_DETAILS,
  //       {
  //         created_by: users.user._id,
  //         // notforbdm: 1,
  //         opening_id: id,
  //       },
  //       {
  //         headers: { Authorization: users.token },
  //       }
  //     )
  //     .then((res) => {
  //       const data = res.data.data;
  //       if (data.length > 0) {
  //         let assigned_rec_arr = [];
  //         let assigned_free_arr = [];
  //         data.forEach((element) => {
  //           assigned_rec_arr.push(...element.assigned_recruiter);
  //           assigned_free_arr.push(...element.assigned_freelancer);
  //         });
  //         setSelectedRecruter(assigned_rec_arr);
  //         setSelectedFreelancer(assigned_free_arr);
  //       }

  //       // getJobAssignmentDetailsWithoutBdm(id);
  //     })
  //     .catch((error) => {});
  // };

  const setDefaultState = () => {
    setTimeout(() => {
      setSuccess(false);
      setLoading(false);
      setError(false);
      setMsgSuccess("");
      setmsgError("");
    }, 3000);
  };

  // console.log("openingDetails", openingDetails.);
  return (
    <div className="p-5">
      <Row gutter={24} className="m-0 p-0">
        <Col span={14} className="p-0">
          <OpeningDetailUi
            record={openingDetails}
            openingDetails={openingDetails}
            goBack={props.history.goBack}
          />
          <br />
          <ActivityLogUI param={openingDetails} isActivityLogs={false} />
        </Col>
        <Col span={10} className="pr-0">
          <ActivityLogUI param={openingDetails} isActivityLogs={true} />

          <Card title="Assign Job" className="p-0 my-3">
            <Row gutter={24}>
              <Col span={20}>
                {/*{openingDetails && openingDetails.assigned_bdm.length <= 0 ? (*/}
                <Text>Assigned Job to BDM:</Text>
                <FormItem>
                  <Row className="d-flex justify-content-between">
                    <Col span={15}>
                      <Select
                        mode="multiple"
                        value={selectedBDM}
                        onChange={handleAssignChange}
                        placeholder="Select"
                      >
                        {allBDM !== undefined &&
                          allBDM.map((user, index) => {
                            return (
                              <Option value={user._id} key={index.toString()}>
                                {user.display_name.charAt(0).toUpperCase() +
                                  user.display_name.slice(1)}
                              </Option>
                            );
                          })}
                      </Select>
                    </Col>
                    <Button
                      className="d-flex align-items-center"
                      type="primary"
                      onClick={postAssignJob}
                    >
                      Save
                      {loading && (
                        <span className="mx-3 spinner spinner-white"> </span>
                      )}
                    </Button>
                  </Row>
                </FormItem>

                <br />

                <Text>Assigned Job to Recruiter :</Text>
                <FormItem className="mt-2">
                  <Row className="d-flex justify-content-between">
                    <Col span={15}>
                      <Select
                        mode="multiple"
                        value={selectedRecruiter}
                        onChange={(e) => {
                          setSelectedRecruiter(e);
                        }}
                        placeholder="Select"
                      >
                        {recruiterList.length > 0
                          ? recruiterList.map((user, index) => {
                              return (
                                <Option value={user._id} key={index.toString()}>
                                  {user.display_name
                                    ? user.display_name
                                        .charAt(0)
                                        .toUpperCase() +
                                      user.display_name.slice(1)
                                    : ""}
                                </Option>
                              );
                            })
                          : null}
                      </Select>
                    </Col>

                    {null ? null : (
                      <Button
                        className="d-flex align-items-center"
                        type="primary"
                        onClick={() => postAssignJob("Recruiter")}
                      >
                        Save
                        {loading && (
                          <span className="mx-3 spinner spinner-white"> </span>
                        )}
                      </Button>
                    )}
                  </Row>
                </FormItem>

                {/*) : (*/}
                <>
                  {/* <Text type="secondary">{"Job Assigned to "}</Text>
                  <Text type="primary">
                    {openingDetails && openingDetails.assigned_bdm.length > 0
                      ? openingDetails.assigned_bdm.map(e => {
                        return e.display_name + ', '
                      })
                      : ""}
                  </Text> */}
                </>
                {/*)}*/}
                {showError(success, msgSuccess, error, msgError)}
              </Col>
            </Row>
          </Card>

          <BooleanString
  jobId={openingDetails._id}
  description={openingDetails?.job_description}
  category={openingDetails?.category?.[0]?.name ?? ""}
  title={openingDetails?.opening_title}
  city={openingDetails?.city?.[0]?.city ?? ""}
  state={openingDetails?.state?.[0]?.state ?? ""}
  country={openingDetails?.country}
  required_skills={openingDetails?.required_skills}
/>
          <>
            <Row gutter={24} className="mt-4">
              <Col span={12}>
                <Button
                  type="primary"
                  icon={<ShareAltOutlined />}
                  onClick={showModal}
                  block
                >
                  Share
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  type="default"
                  icon={<RoomOutlined />}
                  onClick={showEmbedModal}
                  block
                >
                  Embed Code
                </Button>
              </Col>
            </Row>
            {/* Embed Code Modal */}
            <Modal
              title="Embed Job Posting on Your Website"
              open={isEmbedModalOpen}
              onCancel={handleEmbedModalCancel}
              footer={null}
            >
              <Typography.Title level={5}>How to Embed</Typography.Title>
              <Typography.Paragraph>
                Copy the code below and paste it into your website's HTML where
                you want the job posting to appear.
              </Typography.Paragraph>
              <Card bordered style={{ background: "#f5f5f5" }}>
                <pre
                  style={{
                    background: "#333",
                    color: "#fff",
                    padding: "15px",
                    borderRadius: "5px",
                    fontSize: "14px",
                    overflowX: "auto",
                  }}
                >
                  {iframeCode}
                </pre>
              </Card>
              <div style={{ textAlign: "right", marginTop: "15px" }}>
                <Button type="primary" onClick={copyEmbedCode}>
                  Copy to Clipboard
                </Button>
              </div>
            </Modal>
            <Modal
              title="Share this post"
              open={isModalOpen}
              onCancel={handleCancel}
              footer={null}
            >
              <Space size="large">
                <Button
                  icon={<FacebookOutlined />}
                  type="primary"
                  onClick={() => handleShare("facebook")}
                  style={{ backgroundColor: "#3b5998", borderColor: "#3b5998" }}
                >
                  Facebook
                </Button>
                <Button
                  icon={<LinkedinOutlined />}
                  type="primary"
                  onClick={() => handleShare("linkedin")}
                  style={{ backgroundColor: "#0e76a8", borderColor: "#0e76a8" }}
                >
                  LinkedIn
                </Button>
              </Space>
            </Modal>
            <Modal
              title={isEditing ? "Edit AI Campaign" : "Create AI Campaign"}
              visible={isCampaignModalOpen}
              onCancel={handleCancelCampaignModal}
              footer={null}
              width={1000}
              bodyStyle={{ padding: "24px 32px" }}
            >
              <Form
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                initialValues={{
                  ...campaign,
                  campaign_active_days_and_time: {
                    ...campaign?.campaign_active_days_and_time,
                    time_range: {
                      start: campaign?.campaign_active_days_and_time?.time_range
                        ?.start
                        ? moment(
                            campaign.campaign_active_days_and_time.time_range
                              .start,
                            "HH:mm"
                          )
                        : null,
                      end: campaign?.campaign_active_days_and_time?.time_range
                        ?.end
                        ? moment(
                            campaign.campaign_active_days_and_time.time_range
                              .end,
                            "HH:mm"
                          )
                        : null,
                    },
                  },
                }}
              >
                <Row gutter={[24, 24]}>
                  <Col xs={24} sm={12} md={8}>
                    <Form.Item
                      name="campaign_name"
                      label="Campaign Name"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the campaign name",
                        },
                      ]}
                    >
                      <Input placeholder="Enter campaign name" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8}>
                    <Form.Item
                      name={["campaign_active_days_and_time", "days"]}
                      label="Active Days"
                      rules={[
                        {
                          required: true,
                          message: "Please select active days",
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        placeholder="Select active days"
                        allowClear
                      >
                        {daysOptions.map((day) => (
                          <Option key={day} value={day}>
                            {day}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8}>
                    <Form.Item
                      name="campaign_mode"
                      label="Mode"
                      rules={[
                        {
                          required: true,
                          message: "Please select the mode",
                        },
                      ]}
                    >
                      <Select placeholder="Select Mode">
                        <Option value="simple">Simple</Option>
                        <Option value="complex">Complex</Option>
                        <Option value="medium">Medium</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Typography.Title level={5} style={{ marginBottom: "16px" }}>
                  Platforms Sourcing
                </Typography.Title>
                <Row gutter={[24, 24]}>
                  {predefinedPlatforms.map((platform) => (
                    <Col xs={24} sm={12} md={8} key={platform}>
                      <Form.Item
                        name={["platformNumbers", platform]}
                        label={platform}
                        rules={[
                          {
                            type: "number",
                            min: 0,
                            message: "Number must be positive",
                          },
                        ]}
                      >
                        <InputNumber
                          min={0}
                          placeholder="Enter number"
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                  ))}
                </Row>
                <Row gutter={[24, 24]}>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      name="template"
                      label="Select Email Template"
                      rules={[
                        {
                          required: true,
                          message: "Please select an email template",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select an email template"
                        onChange={(value) => {
                          const selectedRecord = predefinedTemplates.find(
                            (template) => template._id === value
                          );
                          handleTemplateSelection(value);
                        }}
                      >
                        {predefinedTemplates.map((template) => (
                          <Option key={template.type} value={template._id}>
                            {template.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Form.Item>
                      <Button
                        type="link"
                        onClick={() => {
                          setIsEmailTemplateModalOpen(true);
                          setIsisCampaignModalOpen(false);
                        }}
                        style={{ padding: 0 }}
                      >
                        Create New Template
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
                {templateContent.length > 0 && (
                  <Card
                    style={{
                      border: "1px solid #e8e8e8",
                      padding: "16px",
                      background: "#f9f9f9",
                      marginTop: "16px",
                    }}
                  >
                    <Typography.Text strong>Template Preview:</Typography.Text>
                    <div
                      dangerouslySetInnerHTML={{ __html: templateContent }}
                    />
                  </Card>
                )}
                <Row gutter={[24, 24]} style={{ marginTop: "16px" }}>
                  <Col xs={24} sm={12}>
                    {/* email_to_reach_candidate */}

                    <Form.Item
                      name="email_to_reach_candidate"
                      label="Email to Reach"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the email address",
                        },
                      ]}
                    >
                      <Input placeholder="Enter email address" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      label="Active Time Range"
                      style={{ marginBottom: 0 }}
                    >
                      <Row gutter={8}>
                        <Col span={12}>
                          <Form.Item
                            name={[
                              "campaign_active_days_and_time",
                              "time_range",
                              "start",
                            ]}
                            rules={[
                              {
                                required: true,
                                message: "Please select start time",
                              },
                            ]}
                          >
                            <TimePicker
                              format="HH:mm"
                              placeholder="Start Time"
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            name={[
                              "campaign_active_days_and_time",
                              "time_range",
                              "end",
                            ]}
                            rules={[
                              {
                                required: true,
                                message: "Please select end time",
                              },
                            ]}
                          >
                            <TimePicker
                              format="HH:mm"
                              placeholder="End Time"
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[24, 24]}>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      name="start_date"
                      label="Start Date"
                      rules={[
                        {
                          required: true,
                          message: "Please select the start date",
                        },
                      ]}
                    >
                      <DatePicker
                        format="YYYY-MM-DD"
                        placeholder="Select start date"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      name="end_date"
                      label="End Date"
                      rules={[
                        {
                          required: true,
                          message: "Please select the end date",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (
                              !value ||
                              getFieldValue("start_date") <= value
                            ) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error("End date must be after start date")
                            );
                          },
                        }),
                      ]}
                    >
                      <DatePicker
                        format="YYYY-MM-DD"
                        placeholder="Select end date"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[24, 24]}>
                  <Col span={24}>
                    <Form.Item>
                      <Button type="primary" htmlType="submit" block>
                        {isEditing ? "Update Campaign" : "Submit Campaign"}
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Modal>

            <Modal
              title="Create Email Template"
              visible={isEmailTemplateModalOpen}
              onCancel={() => {
                setIsEmailTemplateModalOpen(false);
              }}
              footer={null}
              width={800}
              bodyStyle={{ padding: "24px 32px" }}
            >
              <Form
                form={emailTemplateForm}
                layout="vertical"
                onFinish={handleSaveEmailTemplate}
              >
                {/* Template Subject */}
                <Form.Item
                  name="template_name"
                  label={<strong>Template Name</strong>}
                  rules={[
                    {
                      required: true,
                      message: "Please enter the template name",
                    },
                  ]}
                >
                  <Input placeholder="Enter email subject" />
                </Form.Item>
                {/* Template Subject */}
                <Form.Item
                  name="subject"
                  label={<strong>Subject</strong>}
                  rules={[
                    {
                      required: true,
                      message: "Please enter the email subject",
                    },
                  ]}
                >
                  <Input placeholder="Enter email subject" />
                </Form.Item>

                {/* Variable Options */}
                <Typography.Text strong>Add Variables:</Typography.Text>
                <div style={{ marginBottom: "10px" }}>
                  {[
                    { label: "Opening Title", tag: "{{OpeningTitle}}" },
                    { label: "Candidate Name", tag: "{{CandidateName}}" },
                    { label: "Short Description", tag: "{{ShortDescription}}" },
                  ].map((variable) => (
                    <Button
                      key={variable.tag}
                      type="default"
                      size="small"
                      style={{ marginRight: "8px", marginBottom: "8px" }}
                      onClick={() => appendVariable(variable.tag)}
                    >
                      {variable.label}
                    </Button>
                  ))}
                </div>

                <Form.Item
                  name="content"
                  label={<strong>Content</strong>}
                  rules={[
                    {
                      required: true,
                      message: "Please enter the email content.",
                    },
                  ]}
                >
                  <CKEditor
                    editor={ClassicEditor}
                    data={form.getFieldValue("content")} // Initialize with current form value
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      emailTemplateForm.setFieldsValue({ content: data });
                      setEditor(editor); // Save the editor instance
                    }}
                  />
                </Form.Item>

                {/* Save Button */}
                <Form.Item>
                  <Button type="primary" htmlType="submit" block>
                    Save Template
                  </Button>
                </Form.Item>
              </Form>
            </Modal>
          </>
        </Col>
      </Row>
    </div>
  );
}
