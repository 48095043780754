import {
  ColumnHeightOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Empty,
  Input,
  InputNumber,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import {
  CREATE_OR_UPDATE_PREFERENCE,
  GET_JOB_OPENINGS,
  JOB_BDM_LIST,
  JOB_DELETE,
  JOB_DETAIL,
  JOB_OPENINGS,
  JOB_RECRUITER_LIST,
  JOB_UPDATE,
  PREFERENCE_LIST,
} from "../../../ApiUrl";
import { useCallAPI } from "../../../hooks/useCallAPI";
import { handleSearch } from "../../../others/handler/handleSearch";
import { requestHeader } from "../../../others/header";
import ExpandableContent from "../../../others/utils/ExpandableContent";
import { OpeningStatusList } from "../../pages/constant/constant";
import { getFormatDate } from "../../pages/utils/helpers";
import EditColumns from "../Modals/EditColumns";
import Notes from "../Modals/Notes";
import { CustomSnackbar } from "../others/Snackbar";
import { toAbsoluteUrl } from "../../../_theme_parts/_helpers";

// Status Color Mapping
const STATUS_COLORS = {
  active: "green",
  pending: "orange",
  closed: "red",
  draft: "blue",
  "in-progress": "purple",
};

export function OpeningList_v2() {
  const users = useSelector(({ users }) => users);
  const createNewClientPath =
    users.role === "admin"
      ? "admin"
      : users.role === "recruitmentmanager"
      ? "recruitment-manager"
      : users.role === "bdmmanager"
      ? "bdm-manager"
      : users.role;
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: localStorage.getItem("jobOpeningsPageSize") || 10,
  });
  const [search, setSearch] = useState("");
  const [visibleColumns, setVisibleColumns] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const JobOpenings = useCallAPI(JOB_OPENINGS);
  const DeleteJob = useCallAPI(JOB_DELETE);
  const UpdateOpeningStatus = useCallAPI(`${JOB_DETAIL}/status`);
  const HandleColumnView = useCallAPI(CREATE_OR_UPDATE_PREFERENCE);
  const ColumnViewList = useCallAPI(
    `${PREFERENCE_LIST}?field="job_openings_preference"`
  );
  const BDMList = useCallAPI(JOB_BDM_LIST);
  const RecruiterList = useCallAPI(JOB_RECRUITER_LIST);
  const UpdateJobOpenings = useCallAPI(JOB_UPDATE);
  const NotesData = useCallAPI(GET_JOB_OPENINGS);
  const [statusFilter, setStatusFilter] = useState(null);
  const timeoutRef = useRef(null);

  const onDelete = (recordId) => {
    DeleteJob.deletee(recordId)
      .then(() => {
        callJobOpenings();
      })
      .catch((error) => {});
  };
  const callJobOpenings = () => {
    const queryParams = new URLSearchParams({
      current_page: pagination?.page?.toString(),
      per_page: pagination?.pageSize?.toString(),
      search: search ? search.trim() : "",
    });

    if (statusFilter !== null) {
      queryParams.append("status", statusFilter);
    }

    JobOpenings.getList(queryParams.toString());
  };
  const handleColumn = (key, show) => {
    setColumns(
      columns?.map((column) =>
        column.key === key ? { ...column, show: show } : column
      )
    );
  };

  const saveViewPreference = () => {
    HandleColumnView.post({
      job_openings_preference: columns?.map((col) => ({
        title: col.title,
        show: col.show,
        dataIndex: col.dataIndex,
      })),
    });
    setVisibleColumns(false);
  };

  useEffect(() => {
    if (!JobOpenings.loading) {
      callJobOpenings();
    }
  }, [pagination, search, statusFilter]);

  useEffect(() => {
    getListPreference();
  }, []);

  const getColumnPreference = async () => {
    try {
      await ColumnViewList.getList(); // Ensure this function is correctly fetching the data
      const data = ColumnViewList?.data?.data;

      if (data) {
        const updatedColumns = columns?.map((column) => {
          const preference = data?.job_openings_preference?.find(
            (pref) =>
              JSON.stringify(pref.title) === JSON.stringify(column.title)
          );
          if (preference) {
            return { ...column, show: preference.show };
          }
          return column;
        });
        setColumns(updatedColumns);
      }
    } catch (error) {}
  }; //need to add it

  const getListPreference = () => {
    axios
      .get(
        `${PREFERENCE_LIST}?field="job_openings_preference"`,
        requestHeader()
      )
      .then((response) => {
        const updatedColumns = columns?.map((column) => {
          const preference = response.data.data.job_openings_preference.find(
            (pref) =>
              JSON.stringify(pref.title) === JSON.stringify(column.title)
          );
          if (preference) {
            return { ...column, show: preference.show };
          }
          return column;
        });

        setColumns(updatedColumns);
      })
      .catch((error) => {});
  };

  const handleNotes = (value) => {
    // setShowNotes(false);
    UpdateJobOpenings.put({ notes: value }, selectedRow._id);
  };

  const getNotes = (record) => {
    NotesData.get(record.opening_id, "fields='notes,opening_id'");
    setSelectedRow(record);
    setShowNotes(true);
  };

  const [columns, setColumns] = useState([
    {
      title: "Notes",
      dataIndex: "notes",
      show: true,
      width: 120,
      key: () => {
        this.dataIndex;
      },
      render: (data, record) => (
        <Space>
          <Tooltip title="View/Add Notes">
            <Button
              type="text"
              // icon={<CopyOutlined />}
              icon={
                <img
                  src={toAbsoluteUrl("/media/svg/icons/Extra/note.png")}
                  width="25px"
                  alt="note"
                />
              }
              onClick={() => getNotes(record)}
              className="action-btn notes-btn"
            />
          </Tooltip>
        </Space>
      ),
    },
    {
      title: "Opening Title",
      dataIndex: "opening_title",
      show: true,
      key: () => {
        this.dataIndex;
      },
      render: (data, record) => (
        <Tooltip
          color="#1a1a27"
          title={
            <Space>
              <Tooltip title="View Details">
                <Link
                  to={{
                    pathname: `/${createNewClientPath}/opening-detail/${record.opening_id}`,
                    state: { record: record },
                  }}
                >
                  <Button
                    type="primary"
                    icon={<EyeOutlined />}
                    className="action-btn view-btn"
                  ></Button>
                </Link>
              </Tooltip>
              <Tooltip title="Edit Opening">
                <NavLink
                  to={{
                    pathname: `/${createNewClientPath}/add-opening`,
                    state: { record: record },
                  }}
                >
                  <Button
                    type="default"
                    icon={<EditOutlined />}
                    className="action-btn edit-btn"
                  />
                </NavLink>
              </Tooltip>
              <Popconfirm
                title="Delete this job opening?"
                onConfirm={() => onDelete(record._id)}
                okText="Yes"
                cancelText="No"
              >
                <Tooltip title="Delete Opening">
                  <Button
                    type="danger"
                    icon={<DeleteOutlined />}
                    className="action-btn delete-btn"
                  />
                </Tooltip>
              </Popconfirm>
            </Space>
          }
        >
          <NavLink
            to={{
              pathname: `/${createNewClientPath}/opening-detail/${record._id}`,
              state: { record: record },
            }}
          >
            {data}
          </NavLink>
        </Tooltip>
      ),
    },
    {
      title: "Skills",
      dataIndex: "required_skills",
      show: true,
      key: () => {
        this.dataIndex;
      },
      render: (skills, record) => {
        const skillContent = Array.isArray(skills) ? skills.join(", ") : skills;
        return (
          <ExpandableContent
            content={skillContent}
            maxHeight={48}
            maxCharacters={100}
            showMoreText="Show more skills"
            showLessText="Show fewer skills"
          />
        );
      },
    },

    {
      title: "Visa",
      dataIndex: "visa_type",
      show: false,
      key: () => {
        this.dataIndex;
      },
      render: (text) => {
        const visaContent = Array.isArray(text) ? text.join(", ") : text;

        return (
          <Typography.Text>{visaContent ? visaContent : "N/A"}</Typography.Text>
        );
      },
    },
    {
      title: "Pay By",
      dataIndex: "pay_currency",
      show: false,
      key: () => {
        this.dataIndex;
      },
    },
    {
      title: "Pay Type",
      dataIndex: "pay_type",
      show: false,
      key: () => {
        this.dataIndex;
      },
      render: (text) => {
        return (
          <Typography.Text className="text-capitalize">
            {text ? text : "N/A"}
          </Typography.Text>
        );
      },
    },
    {
      title: "Address",
      show: true,
      key: () => {
        this.dataIndex;
      },
      render: (_, record) => {
        const { country, state, city, zip_code } = record;
        return [
          city && city + (state ? `, ` : ""),
          state && state + (country ? `, ` : ""),
          country && country + (zip_code ? `, ` : ""),
          zip_code > 0 ? zip_code : "",
        ]
          .filter(Boolean)
          .join(" ");
      },
    },
    {
      title: "Vacancy",
      dataIndex: "number_of_openings",
      show: true,
      key: () => {
        this.dataIndex;
      },
    },
    {
      title: "Candidates",
      dataIndex: "candidate_submission_count",
      show: true,
      key: () => {
        this.dataIndex;
      },
    },
    {
      title: "Autosourced",
      dataIndex: "autosourced_candidates_count",
      show: true,
      key: () => {
        this.dataIndex;
      },
    },
    {
      title: "Type",
      dataIndex: "employment_type",
      show: false,
      key: () => {
        this.dataIndex;
      },
    },
    {
      title: "Experience Required",
      dataIndex: "required_experience",
      show: false,
      key: () => {
        this.dataIndex;
      },
    },
    {
      title: "Experience Level",
      dataIndex: "experience_level",
      show: false,
      key: () => {
        this.dataIndex;
      },
    },
    {
      title: "Interview Type",
      dataIndex: "interview_type",
      show: false,
      key: () => {
        this.dataIndex;
      },
      render: (text, record) => {
        const { interview_type } = record;
        return interview_type
          ? interview_type === "ph"
            ? "Phone Interview"
            : interview_type === "vi"
            ? "Video Interview"
            : interview_type === "fa"
            ? "Face to Face"
            : interview_type === "pi"
            ? "Phone Interview"
            : interview_type === "pa"
            ? "Panel Interview"
            : interview_type === "sv"
            ? "Skype/Video Interview"
            : "Not Specified"
          : "Not Specified";
      },
    },

    {
      title: "Salary",
      dataIndex: "salary_range_from",
      show: true,
      key: () => {
        this.dataIndex;
      },
      render: (text, record) => {
        const {
          salary_range_from,
          salary_range_to,
          currency,
          salary_type,
        } = record;
        return `${salary_range_from}-${salary_range_to} ${currency}/${salary_type}`;
      },
    },
    {
      title: "Recruiters",
      dataIndex: "recruiterDetails",
      show: false,
      key: () => {
        this.dataIndex;
      },
      render: (_, record) => {
        const { recruiterDetails, freelancerDetails } = record;
        const allRecruiter = recruiterDetails?.concat(freelancerDetails);
        return allRecruiter.length > 0
          ? allRecruiter?.map((rec) => rec?.display_name).join(", ")
          : "Not Assigned";
      },
    },
    {
      title: "BDMs",
      dataIndex: "bdmDetails",
      show: true,
      key: () => {
        this.dataIndex;
      },
      render: (_, record) => {
        const { bdmDetails } = record;
        return bdmDetails?.length > 0 ? (
          bdmDetails?.map((bdm) => (
            <Tag key={bdm._id} color="blue">
              {bdm?.display_name}
            </Tag>
          ))
        ) : (
          <Tag color="default">Not Assigned</Tag>
        );
      },
    },
    {
      title: "Posted Date",
      dataIndex: "created_at",
      show: true,
      key: () => {
        this.dataIndex;
      },
      render: (date) => {
        return date ? getFormatDate(date) : "";
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      show: true,
      render: (status, record) => (
        <Select
          value={status}
          onChange={async (newStatus) => {
            await UpdateOpeningStatus.put({ status: newStatus }, record._id);
            callJobOpenings();
          }}
          className="status-select"
        >
          {OpeningStatusList?.map((statusOption) => (
            <Select.Option key={statusOption.value} value={statusOption.value}>
              <Tag color={STATUS_COLORS[statusOption.value] || "default"}>
                {statusOption.label}
              </Tag>
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      title: "Action",
      dataIndex: "",
      show: true,
      key: () => {
        this.dataIndex;
      },
      width: 150,
      render: (_, record) => (
        <Space>
          <Tooltip title="View Details">
            <Link
              to={{
                pathname: `/${createNewClientPath}/opening-detail/${record._id}`,
                state: { record: record },
              }}
            >
              <Button
                type="primary"
                icon={<EyeOutlined />}
                className="action-btn view-btn"
              ></Button>
            </Link>
          </Tooltip>
          <Tooltip title="Edit Opening">
            <NavLink
              to={{
                pathname: `/${createNewClientPath}/add-opening`,
                state: { record: record },
              }}
            >
              <Button
                type="default"
                icon={<EditOutlined />}
                className="action-btn edit-btn"
              />
            </NavLink>
          </Tooltip>
          <Popconfirm
            title="Delete this job opening?"
            onConfirm={() => onDelete(record._id)}
            okText="Yes"
            cancelText="No"
          >
            <Tooltip title="Delete Opening">
              <Button
                type="danger"
                icon={<DeleteOutlined />}
                className="action-btn delete-btn"
              />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ]);

  useEffect(() => {
    const initialPage = window.history.state?.page || 1;
    setPagination({ ...pagination, page: initialPage });

    const handlePopState = (event) => {
      const page = event.state?.page || 1;

      setPagination({ ...pagination, page: page });
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);
  const handleStatusFilterChange = (value) => {
    const newStatus = value === "" ? null : value;
    setStatusFilter(newStatus);
    setPagination((prev) => ({ ...prev, page: 1 }));
  };
  return (
    <>
      <div className="job-openings-container">
        <EditColumns
          columns={columns}
          isVisible={visibleColumns}
          handleCancel={() => {
            setVisibleColumns(false);
          }}
          handleColumn={handleColumn}
          save={saveViewPreference}
        />
        <Notes
          handleCancel={() => setShowNotes(false)}
          isVisible={showNotes}
          handleNotes={handleNotes}
          notes={NotesData}
          jobOpenings={selectedRow}
          user={users}
          updateNoteState={setShowNotes}
        />
        {/* <Filters callBack={(e) => {}} showCategory={true} showCompany={false} />  */}
        <CustomSnackbar
          success={
            DeleteJob.success ||
            UpdateOpeningStatus.success ||
            UpdateJobOpenings.success
          }
          error={
            DeleteJob.error ||
            UpdateOpeningStatus.error ||
            UpdateJobOpenings.error
          }
        />
        <Card
          title="Job Openings"
          extra={
            <Space>
              <Select
                placeholder="Filter Status"
                value={statusFilter}
                onChange={handleStatusFilterChange}
                className="status-filter"
                suffixIcon={<SearchOutlined />}
              >
                <Select.Option value="">All Statuses</Select.Option>
                {OpeningStatusList?.map((status) => (
                  <Select.Option key={status.value} value={status.value}>
                    <Tag color={STATUS_COLORS[status.value] || "default"}>
                      {status.label}
                    </Tag>
                  </Select.Option>
                ))}
              </Select>
              <NavLink to={`/${createNewClientPath}/add-opening`}>
                <Button type="primary" className="add-opening-btn">
                  Add New Opening
                </Button>
              </NavLink>
            </Space>
          }
        >
          <Row
            justify="space-between"
            align="middle"
            className="table-controls"
          >
            <Col>
              <Input
                prefix={<SearchOutlined />}
                placeholder="Search openings..."
                onChange={(e) => handleSearch(e, timeoutRef, setSearch)}
                className="search-input"
              />
            </Col>
            <Col>
              <Space>
                <Tooltip title="Rows per page">
                  <InputNumber
                    min={5}
                    max={100}
                    value={pagination.pageSize}
                    onChange={(value) => {
                      setPagination({ ...pagination, pageSize: value });
                      localStorage.setItem("jobOpeningsPageSize", value);
                    }}
                    className="page-size-input"
                  />
                </Tooltip>
                <Button
                  icon={<ColumnHeightOutlined />}
                  onClick={() => setVisibleColumns(true)}
                  className="columns-btn"
                >
                  Customize Columns
                </Button>
              </Space>
            </Col>
          </Row>
          {JobOpenings.data?.data?.length > 0 ? (
            <Table
              pagination={{
                total: JobOpenings.data?.paginate?.totalData,
                showSizeChanger: false,
                pageSize: pagination.pageSize,
                current: pagination.page,
                onChange(current) {
                  setPagination({ ...pagination, page: current });
                  window.history.pushState(
                    { page: current },
                    "",
                    `?page=${current}`
                  );
                },
              }}
              scroll={{
                x: "auto",
                y: "calc(100vh - 300px)",
              }}
              dataSource={JobOpenings.data.data}
              columns={columns.filter((column) => column.show)}
              loading={JobOpenings.loading}
              className="job-openings-table"
              rowClassName="job-openings-row"
            />
          ) : (
            <Empty description="No openings found." />
          )}
        </Card>
      </div>
    </>
  );
}
