import React, { useState, useEffect } from "react";
import {
  Checkbox,
  TimePicker,
  Button,
  Space,
  Row,
  Col,
  message,
  Modal,
  DatePicker,
  Calendar,
  Card,
} from "antd";
import moment from "moment";
import {
  PlusOutlined,
  DeleteOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import axios from "axios";

import { useSelector } from "react-redux";
import {
  COPILOT_CAMPAIGN_TIME_SLOTS_GET,
  COPILOT_TIME_SLOT_SELECTION,
} from "../../../ApiUrl";
import DateSpecificSlots from "./DateSpecificSlots";
import WeekHours from "./WeeklyHours";

const WeeklyHours = ({ campaignId }) => {
  const defaultTime = moment("00:00", "HH:mm");
  const users = useSelector(({ users }) => users);
  const [selectedDates, setSelectedDates] = useState([]);
  const [sharedSlots, setSharedSlots] = useState([
    { start: moment("09:00", "HH:mm"), end: moment("17:00", "HH:mm") },
  ]);
  const defaultSchedule = {
    Sunday: [],
    Monday: [{ start: defaultTime, end: defaultTime }],
    Tuesday: [{ start: defaultTime, end: defaultTime }],
    Wednesday: [{ start: defaultTime, end: defaultTime }],
    Thursday: [{ start: defaultTime, end: defaultTime }],
    Friday: [{ start: defaultTime, end: defaultTime }],
    Saturday: [],
  };

  const [schedule, setSchedule] = useState(defaultSchedule);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dateSpecificSlots, setDateSpecificSlots] = useState({});
  const [currentDate, setCurrentDate] = useState(moment());

  const onMonthChange = (direction) => {
    setCurrentDate(
      direction === "prev"
        ? moment(currentDate).subtract(1, "month")
        : moment(currentDate).add(1, "month")
    );
  };

  useEffect(() => {
    const fetchTimeSlots = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${COPILOT_CAMPAIGN_TIME_SLOTS_GET}/${campaignId}`,
          {
            headers: {
              Authorization: users.token,
            },
          }
        );
        const slots = response?.data.data?.slots;

        if (slots) {
          const fetchedSchedule = {};
          slots.forEach(({ day, times }) => {
            fetchedSchedule[day] = times.map(({ start, end }) => ({
              start: moment(start, "HH:mm"),
              end: moment(end, "HH:mm"),
            }));
          });
          setSchedule(fetchedSchedule);
        } else {
          setSchedule(defaultSchedule);
        }
      } catch (error) {
        setSchedule(defaultSchedule);
      } finally {
        setLoading(false);
      }
    };

    fetchTimeSlots();
  }, [campaignId, users.token]);

  const handleAddInterval = (day) => {
    setSchedule((prev) => ({
      ...prev,
      [day]: [...prev[day], { start: defaultTime, end: defaultTime }],
    }));
  };
  const toggleDateSelection = (date) => {
    setSelectedDates((prev) =>
      prev.includes(date) ? prev.filter((d) => d !== date) : [...prev, date]
    );
  };

  const handleRemoveInterval = (day, index) => {
    setSchedule((prev) => ({
      ...prev,
      [day]: prev[day].filter((_, i) => i !== index),
    }));
  };

  const handleTimeChange = (day, index, field, value) => {
    setSchedule((prev) => {
      const updatedDay = [...prev[day]];
      updatedDay[index][field] = value;
      return { ...prev, [day]: updatedDay };
    });
  };

  const handleSharedTimeChange = (index, type, value) => {
    setSharedSlots((prev) => {
      const updated = [...prev];
      updated[index][type] = value;
      return updated;
    });
  };

  const handleAddSharedSlot = () => {
    setSharedSlots((prev) => {
      if (prev.length === 0) {
        return [
          ...prev,
          {
            start: moment("09:00 AM", "hh:mm A"),
            end: moment("05:00 PM", "hh:mm A"),
          },
        ];
      } else {
        const lastSlot = moment(prev[prev.length - 1].end, "hh:mm A");
        const nextStartTime = lastSlot.clone().add(1, "hour");
        const nextEndTime = nextStartTime.clone().add(1, "hour");

        return [
          ...prev,
          {
            start: nextStartTime,
            end: nextEndTime,
          },
        ];
      }
    });
  };

  const handleRemoveSharedSlot = (index) => {
    setSharedSlots((prev) => prev.filter((_, i) => i !== index));
  };

  const handleRemoveDateSlot = (date, index) => {
    setDateSpecificSlots((prev) => ({
      ...prev,
      [date]: prev[date].filter((_, i) => i !== index),
    }));
  };

  const handleApplyDateSlots = () => {
    const dateSpecificData = selectedDates.map((date) => {
      return {
        date: date, // The selected date
        slots: sharedSlots.map((slot) => ({
          start: slot.start.format("HH:mm"), // Time in HH:mm format
          end: slot.end.format("HH:mm"), // Time in HH:mm format
        })),
      };
    });

    // Prepare the data to be sent to the API
    const dataToSend = {
      datesAndSlots: dateSpecificData, // Array of selected date slots
    };

    // Sending the prepared data to the API
    setIsModalOpen(false);
    console.log("Sending data:", dataToSend);

    // You can call the API here using axios, for example:
    axios
      .post(COPILOT_TIME_SLOT_SELECTION, dataToSend, {
        headers: { Authorization: users.token },
      })
      .then((response) => {
        message.success("Slots applied successfully!");
      })
      .catch((error) => {
        message.error("Failed to apply slots");
      });
  };

  const renderDayRow = (day, intervals) => (
    <div key={day} style={{ marginBottom: "12px" }}>
      <Row align="middle" gutter={16}>
        {/* Checkbox with Day Name */}
        <Col span={6}>
          <Checkbox
            checked={!!intervals.length}
            onChange={(e) =>
              setSchedule((prev) => ({
                ...prev,
                [day]: e.target.checked
                  ? [{ start: defaultTime, end: defaultTime }]
                  : [],
              }))
            }
          >
            <strong>{day}</strong>
          </Checkbox>
        </Col>

        {/* Time Intervals */}
        <Col span={12}>
          {intervals.length === 0 ? (
            <p>Unavailable</p>
          ) : (
            intervals.map((interval, index) => (
              <Row
                key={index}
                gutter={8}
                align="middle"
                style={{ marginBottom: 8 }}
              >
                <Col span={10}>
                  <TimePicker
                    value={interval.start}
                    format="HH:mm"
                    onChange={(value) =>
                      handleTimeChange(day, index, "start", value)
                    }
                    style={{ width: "100%" }}
                  />
                </Col>
                <Col span={2} style={{ textAlign: "center" }}>
                  -
                </Col>
                <Col span={10}>
                  <TimePicker
                    value={interval.end}
                    format="HH:mm"
                    onChange={(value) =>
                      handleTimeChange(day, index, "end", value)
                    }
                    style={{ width: "100%" }}
                  />
                </Col>
                <Col>
                  <Button
                    type="text"
                    icon={<DeleteOutlined />}
                    onClick={() => handleRemoveInterval(day, index)}
                  />
                </Col>
              </Row>
            ))
          )}
        </Col>

        {/* Add Slot Button */}
        <Col span={6}>
          <Button
            type="text"
            icon={<PlusOutlined />}
            onClick={() => handleAddInterval(day)}
            block
          />
        </Col>
      </Row>
    </div>
  );

  return (
    <div style={{ padding: "20px", display: "flex", gap: "20px" }}>
      <WeekHours campaignId={campaignId} />

      <DateSpecificSlots />
    </div>
  );
};

export default WeeklyHours;
