import React, { useState, useEffect } from "react";
import {
  Tabs,
  Card,
  Table,
  Spin,
  Alert,
  Button,
  Timeline,
  Badge,
  Col,
  Row,
  Tag,
} from "antd";
import axios from "axios";
import { useParams } from "react-router";
import {
  COPILOT_APPROVE_BOOLEAN_STRING,
  COPILOT_GET_CAMPAIGN_DETAIL_BY_ID,
  COPILOT_REGENRATE_BOOLEAN_STRING,
} from "../../../ApiUrl";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { CheckOutlined, EyeOutlined } from "@ant-design/icons";
import { Chat, Refresh } from "@material-ui/icons";
import EmailConversationModal from "./EmailConvoModal";
import WeeklyHours from "./CampaignSlots";

const { TabPane } = Tabs;

const CampaignDetailPage = () => {
  const [campaign, setCampaign] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { campaignId } = useParams();
  const [modalVisible, setModalVisible] = useState(false);
  const users = useSelector(({ users }) => users);
  const [sourcedCandidateId, setSourcedCandidateId] = useState(null);
  const createNewClientPath =
    users.role === "admin"
      ? "admin"
      : users.role === "recruitmentmanager"
      ? "recruitment-manager"
      : users.role === "bdmmanager"
      ? "bdm-manager"
      : users.role;

  // Fetch campaign details
  const fetchCampaignDetails = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${COPILOT_GET_CAMPAIGN_DETAIL_BY_ID}/${campaignId}`,
        {
          headers: { Authorization: users.token },
        }
      );
      setCampaign(response.data.data); // Assuming response data contains `data`
    } catch (err) {
      setError(err.message || "Failed to fetch campaign details.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCampaignDetails();
  }, [campaignId]);

  // Handle Regenerate Boolean String
  const handleRegenerate = async (index) => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${COPILOT_REGENRATE_BOOLEAN_STRING}`,
        { campaign_id: campaignId },
        {
          headers: { Authorization: users.token },
        }
      );
      await fetchCampaignDetails();

      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err.message || "Failed to regenerate boolean string.");
    }
  };

  // Handle Approve Boolean String
  const handleApprove = async (index) => {
    try {
      setLoading(true);
      await axios.post(
        `${COPILOT_APPROVE_BOOLEAN_STRING}`,
        { campaign_id: campaignId },
        {
          headers: { Authorization: users.token },
        }
      );
      await fetchCampaignDetails();

      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err.message || "Failed to approve boolean string.");
    }
  };

  // Columns for sourced candidates table
  const sourcedCandidatesColumns = [
    {
      title: "Name",
      dataIndex: ["candidate", "first_name"],
      key: "name",
    },
    {
      title: "Email",
      dataIndex: ["candidate", "email"],
      key: "email",
    },
    {
      title: "Platform Sourced From",
      dataIndex: "platform_sourced_from",
      key: "platform_sourced_from",
    },
    {
      title: "AI Score",
      dataIndex: "ai_score",
      key: "ai_score",
    },

    {
      title: "Email Verified",
      dataIndex: "email_verified",
      key: "email_verified",
      render: (verified) => (
        <Tag color={verified ? "green" : "red"}>
          {verified ? "Verified" : "Not Verified"}
        </Tag>
      ),
    },
    {
      title: "Email Sent",
      dataIndex: "email_sent",
      key: "email_sent",
      render: (sent) => (
        <Tag color={sent ? "green" : "red"}>{sent ? "Sent" : "Not Sent"}</Tag>
      ),
    },
    {
      title: "Conversation",
      // dataIndex: "email_sent",
      // key: "email_sent",
      render: (sent, record) => (
        <Button
          onClick={() => {
            setSourcedCandidateId(record._id);
            setModalVisible(true);
          }}
          icon={<Chat />}
        />
      ),
    },
    {
      title: "Current CTC",
      dataIndex: "current_ctc",
      key: "current_ctc",
    },
    {
      title: "Expected CTC",
      dataIndex: "expected_ctc",
      key: "expected_ctc",
    },
    {
      title: "Expected Joining Date",
      dataIndex: "expected_joining_date",
      key: "expected_joining_date",
    },
    {
      title: "Notice Period",
      dataIndex: "notice_period",
      key: "notice_period",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <NavLink
          to={{
            pathname: `/${createNewClientPath}/candidateProfileDetail`,
            state: { item: record.candidate },
          }}
        >
          <Button type="primary" icon={<EyeOutlined />} />
        </NavLink>
      ),
    },
  ];

  // Render Loading/ Error / Content
  if (loading) return <Spin tip="Loading campaign details..." />;
  if (error)
    return <Alert message="Error" description={error} type="error" showIcon />;

  return (
    <Card
      title={`Campaign Details - ${campaign.opening?.opening_title ||
        "Untitled"}`}
    >
      <Tabs defaultActiveKey="1">
        {/* Overview Tab */}
        <TabPane tab="Overview" key="1">
          <Card>
            <Row gutter={16}>
              <Col span={12}>
                <Card bordered={false} title="Campaign Details">
                  <p>
                    <strong>Opening Title:</strong>{" "}
                    {campaign.opening?.opening_title || "N/A"}
                  </p>
                  <p>
                    <strong>Description:</strong>{" "}
                    {campaign.opening?.short_description ||
                      "No description available."}
                  </p>
                  <p>
                    <strong>Total Sourced Candidates:</strong>{" "}
                    {campaign.total_sourced || 0}
                  </p>
                  <p>
                    <strong>Total Candidates from Monster:</strong>{" "}
                    {campaign.total_monster_sourced || 0}
                  </p>
                  <p>
                    <strong>Total Candidates from CareerBuilder:</strong>{" "}
                    {campaign.total_cb_sourced || 0}
                  </p>
                  <p>
                    <strong>Total Candidates from Bix3:</strong>{" "}
                    {campaign.total_local_sourced || 0}
                  </p>
                </Card>
              </Col>
              <Col span={12}>
                <Card bordered={false} title="Campaign Status">
                  <p>
                    <strong>Status:</strong>{" "}
                    <Badge color="green" text={campaign.status} />
                  </p>
                  <p>
                    <strong>Start Date:</strong>{" "}
                    {new Date(campaign.start_date).toLocaleDateString()}
                  </p>
                  <p>
                    <strong>End Date:</strong>{" "}
                    {new Date(campaign.end_date).toLocaleDateString()}
                  </p>
                </Card>
              </Col>
            </Row>

            <Row gutter={[16, 16]} style={{ marginTop: "20px" }}>
              <Col span={12}>
                <Card bordered={false} title="Activity Timeline">
                  {campaign.activity_timeline &&
                  campaign.activity_timeline.length > 0 ? (
                    <Timeline>
                      {campaign.activity_timeline.map((activity, index) => (
                        <Timeline.Item key={index} color="blue">
                          <p>{activity.activity_log}</p>
                          <p>
                            <small>
                              {new Date(activity.created_at).toLocaleString()}
                            </small>
                          </p>
                        </Timeline.Item>
                      ))}
                    </Timeline>
                  ) : (
                    <p>No activity timeline available for this campaign.</p>
                  )}
                </Card>
              </Col>

              <Col span={12}>
                <Card bordered={false} title="Boolean Strings">
                  {campaign.boolean_strings &&
                  campaign.boolean_strings.length > 0 ? (
                    <>
                      {/* Boolean String Display */}
                      <Row gutter={[16, 16]}>
                        {campaign.boolean_strings.map(
                          (booleanString, index) => (
                            <Col span={24} key={index}>
                              <p>{booleanString || "N/A"}</p>
                            </Col>
                          )
                        )}
                      </Row>
                      {/* Action Buttons or Badge */}
                      <Row
                        justify="end"
                        gutter={[16, 16]}
                        align="middle"
                        style={{ marginTop: "16px" }}
                      >
                        <Col>
                          {campaign.is_boolean_string_verified ? (
                            <Badge
                              count="Approved"
                              style={{ backgroundColor: "#52c41a" }} // Green badge for approved
                            />
                          ) : (
                            <>
                              <Button
                                type="default"
                                icon={<Refresh />} // Replace with the regenerate icon you prefer
                                onClick={handleRegenerateAll} // Regenerate action for all strings
                              >
                                Regenerate
                              </Button>
                              <Button
                                type="primary"
                                icon={<CheckOutlined />} // Icon for approving
                                onClick={handleApproveAll} // Approve action for all strings
                              >
                                Approve
                              </Button>
                            </>
                          )}
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <p>No boolean strings available for this campaign.</p>
                  )}
                </Card>
              </Col>
            </Row>
          </Card>
        </TabPane>

        {/* Sourced Candidates Tab */}
        <TabPane tab="Sourced Candidates" key="2">
          <Table
            dataSource={campaign.sourced_candidates.filter(
              (candidate) => candidate._id
            )}
            columns={sourcedCandidatesColumns}
            rowKey={(record) => record._id}
            pagination={{ pageSize: 5 }}
          />

          <EmailConversationModal
            sourcedCandidateId={sourcedCandidateId}
            visible={modalVisible}
            onClose={() => setModalVisible(false)}
          />
        </TabPane>

        <TabPane tab="Interview Slots" key={"3"}>
          <WeeklyHours campaignId={campaignId} />
        </TabPane>
      </Tabs>
    </Card>
  );
};

export default CampaignDetailPage;
