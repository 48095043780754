import React, { useEffect, useState } from "react";
import { Table, Card, Typography, Spin, message, Button } from "antd";
import axios from "axios";
import { COPILOT_GET_USER_CAMPAIGNS } from "../../../ApiUrl";
import { useSelector } from "react-redux";
import CampaignSettings from "./CampaignSettings";
import { Settings } from "@material-ui/icons";
import { EditFilled, EyeOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";

const { Text } = Typography;

const CampaignsListing = () => {
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedCampaign, setSelectedCampaign] = useState(null); // To hold the selected campaign
  const [isModalVisible, setIsModalVisible] = useState(false); // To manage modal visibility
  const history = useHistory();
  const users = useSelector(({ users }) => users);
  const fetchCampaigns = async () => {
    try {
      setLoading(true);
      const response = await axios.get(COPILOT_GET_USER_CAMPAIGNS, {
        headers: { Authorization: users.token },
      });
      setCampaigns(response.data.data); // Assuming response.data.data is an array of campaigns
    } catch (error) {
      message.error("Failed to load campaigns. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const createNewClientPath =
    users.role === "admin"
      ? "admin"
      : users.role === "recruitmentmanager"
      ? "recruitment-manager"
      : users.role === "bdmmanager"
      ? "bdm-manager"
      : users.role;

  useEffect(() => {
    fetchCampaigns();
  }, []);

  // Handle opening the modal and setting the campaign
  const handleEditCampaign = (campaign) => {
    setSelectedCampaign(campaign);
    setIsModalVisible(true);
  };

  // Handle closing the modal
  const handleCloseModal = () => {
    setSelectedCampaign(null);
    setIsModalVisible(false);
  };

  // Define table columns
  const columns = [
    {
      title: "Name",
      dataIndex: "campaign_name",
      key: "campaign_name",
    },
    {
      title: "Opening Title",
      render: (code, row) => row?.opening?.opening_title,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Total Sourcing Required (Local)",
      dataIndex: "type",
      key: "type",
      render: (code, row) => row?.number_of_source_per_platforms?.local,
    },
    {
      title: "Total Sourcing Required (Monster)",
      dataIndex: "type",
      key: "type",
      render: (code, row) => row?.number_of_source_per_platforms?.monster,
    },
    {
      title: "Total Sourcing Required (Career Builder)",
      dataIndex: "type",
      key: "type",
      render: (code, row) => row?.number_of_source_per_platforms?.cb,
    },
    {
      title: "Progress",
      dataIndex: "progress",
      key: "progress",
      render: (code, row) => row?.progress,
    },
    {
      title: "Start at",
      dataIndex: "start_date",
      key: "start_date",
      render: (text) => new Date(text).toLocaleDateString(), // Format the date
    },
    {
      title: "End at",
      dataIndex: "end_date",
      key: "end_date",
      render: (text) => new Date(text).toLocaleDateString(), // Format the date
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "createdAt",
      render: (text) => new Date(text).toLocaleDateString(), // Format the date
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <>
          <Button
            icon={<EditFilled />}
            onClick={() => handleEditCampaign(record)}
          />

          <NavLink
            to={{
              pathname: `/${createNewClientPath}/campaign-detail/${record._id}`,
            }}
          >
            <Button type="primary" icon={<EyeOutlined />} />
          </NavLink>
        </>
      ),
    },
  ];

  if (loading) {
    return (
      <Spin
        tip="Loading campaigns..."
        style={{ display: "block", textAlign: "center", marginTop: "20px" }}
      />
    );
  }

  return (
    <Card title="Campaigns Listing" style={{ margin: "20px" }}>
      {campaigns.length === 0 ? (
        <Text>No campaigns found.</Text>
      ) : (
        <Table
          dataSource={campaigns}
          columns={columns}
          rowKey={(record) => record._id}
          pagination={{ pageSize: 10 }}
        />
      )}
      <CampaignSettings
        campaign={selectedCampaign} // Pass selected campaign
        visible={isModalVisible} // Pass modal visibility state
        onClose={handleCloseModal} // Pass close handler
        fetchCampaigns={fetchCampaigns}
      />
    </Card>
  );
};

export default CampaignsListing;
