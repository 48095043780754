import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {
  AutoComplete,
  Button,
  Card,
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  Popconfirm,
  Radio,
  Row,
  Select,
  Spin,
  Tag,
  Tooltip,
  Typography,
  Upload,
} from "antd";
import { setRole, setToken, setUser } from "../../../redux/actions/users";
import { showError } from "../../pages/utils/helpers";
import { CANDIDATE_RECRUITER_REGISTER, PARSE_CV } from "../../../ApiUrl";
import {
  Courses,
  EducationList,
  months,
  monthsOfExpirance,
  noticePeriod,
  payTypes,
  Universities,
  yearsOfExpirance,
} from "../../pages/constant/constant";
import { requestHeader } from "../../../others/header";
import { logoutHandler } from "../../../others/logout";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  DeleteOutlined,
  DiffOutlined,
  EditOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import moment from "moment";
import TextArea from "antd/lib/input/TextArea";
import Title from "antd/lib/typography/Title";

const { Option } = Select;
const { Text } = Typography;
const FormItem = Form.Item;

function AddCandidate(props) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msgSuccess, setMsgSuccess] = useState("");
  const [msgError, setmsgError] = useState("");
  const [selectedFileList, setSelectedFileList] = useState([]);
  const [selectedFile, setSelectedFile] = useState("");
  const [fileError, setFileError] = useState(false);
  const [parseLoading, setParseLoading] = useState(false);
  const [parseFileError, setParseFileError] = useState(false);
  const [data, setData] = useState(null);
  const [resumeParsed, setResumeParsed] = useState(false);
  const [bottomUploadDisabled, setBottomUploadDisabled] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const formRef = React.useRef();
  const educationFormRef = React.useRef();
  const [selectedPosition, setSelectedposition] = useState(-1);
  const [isCurrentCompany, setCurrentCompany] = useState(false);
  const [employmentArray, setEmploymentArray] = useState([]); // Initialize with an empty array
  const [selectedQualification, setSelectedQualification] = useState(-1);
  const [educationArray, setEducationArray] = useState([]); // Initialize with an empty array
  const [visible, setVisible] = useState(false);
  const [tags, setTags] = useState([]);
  const [skills, setSkills] = useState([]);
  
  const handleChange = (value) => {
    setTags(value);
  };
  const handleSkillChange = (value) => {
    setSkills(value);
  };

  const showDrawer = (position) => {
    if (position == -1) {
      formRef?.current?.resetFields();
    }
    setEditVisible(true);
    setSelectedposition(position);

    // Populate form with the selected position values from employmentArray
    const selectedValues = employmentArray[position];
    if (selectedValues) {
      formRef.current.setFieldsValue({
        designation: selectedValues.designation,
        organization: selectedValues.organization,
        work_since_from_month: selectedValues.work_since_from_month,
        work_since_from_year: selectedValues.work_since_from_year,
        notice_period: selectedValues.notice_period,
        is_current_company: selectedValues.is_current_company,
        work_since_to_year: selectedValues.work_since_to_year,
        work_since_to_month: selectedValues.work_since_to_month,
        annual_salary: selectedValues.annual_salary,
        location: selectedValues.location,
        description_job_profile: selectedValues.description_job_profile,
      });
    }
  };
  const showEducationDrawer = (position) => {
    if (position == -1) {
      educationFormRef?.current?.resetFields();
    }
    setVisible(true); // Show the drawer
    setSelectedQualification(position); // Set the selected position (index)

    // Populate form with the selected position values from educationArray
    const selectedValues = educationArray[position];
    if (selectedValues) {
      educationFormRef.current.setFieldsValue({
        qualification: selectedValues.qualification,
        course: selectedValues.course,
        university: selectedValues.university,
        course_type: selectedValues.course_type,
        passing_year: selectedValues.passing_year,
      });
    }
  };

  const getBase64 = (file, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      let result = reader.result;
      // For DOCX files, we need to adjust the MIME type
      if (
        file.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        result = result.replace(
          "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,",
          "data:application/msword;base64,"
        );
      }
      callback(result);
    });
    reader.readAsDataURL(file);
  };
  const history = useHistory();

  const onFinish = (values) => {
    // if (!resumeParsed && !selectedFile && selectedFileList.length === 0) {
    //   setFileError(true);
    //   return;
    // }

    const year = values?.passing_year
      ? values?.passing_year.format("YYYY")
      : null;
    if (year) {
      values["passing_year"] = year.toString();
    }

    const submitForm = (attachments) => {
      if (attachments) {
        values.attachments = attachments;
      }
      values.status = values.status ? values.status : "Active";
      values.employments = employmentArray;
      values.qualifications = educationArray;
      axios
        .post(CANDIDATE_RECRUITER_REGISTER, values, requestHeader())
        .then((res) => {
          setLoading(false);
          if (!res.data.error) {
            setSuccess(true);
            setLoading(false);
            setError(false);
            setMsgSuccess(res.data.message);
            setDefaultState();
            setTimeout(() => {
              props.history.goBack();
            }, 3000);
          }
        })
        .catch((error) => {
          setSuccess(false);
          setLoading(false);
          setError(true);
          let errorMessage = "";
          {
            error.response.data &&
              Object.entries(error.response.data.errors).map(([key, value]) => {
                return (errorMessage += value + ", ");
              });
          }
          setmsgError(errorMessage);
          setTimeout(() => {
            setSuccess(false);
            setLoading(false);
            setError(false);
            setMsgSuccess("");
            setmsgError("");
          }, 3000);
          if (error?.response?.status == 401) {
            logoutHandler(history);
          }
        });
    };
    // submitForm(selectedFile);

    if (selectedFileList.length > 0) {
      // Convert the manually uploaded file to base64
      getBase64(selectedFileList[0], (result) => {
        submitForm(result);
      });
    } else {
      submitForm();
    }
  };

  const generateArrayOfYears = () => {
    var max = new Date().getFullYear();
    var min = max - 30;
    var years = [];

    for (var i = max; i >= min; i--) {
      const object = { label: i, value: i };
      years.push(object);
    }
    return years;
  };

  const onFinishEmploymentHistory = (values) => {
    // Convert annual salary to string
    values.annual_salary = values.annual_salary
      ? values.annual_salary.toString()
      : "";
    values.notice_period = values.notice_period ? values.notice_period : " ";
    values.description = values.description ? values.description : " ";
    values.annual_salary_currency_type =
      values.annual_salary_currency_type || "Dollars"; // Set default if not provided

    if (selectedPosition !== -1) {
      const updatedArray = [...employmentArray];
      updatedArray[selectedPosition] = {
        ...updatedArray[selectedPosition],
        ...values, // Merge with the existing values
      };
      setEmploymentArray(updatedArray);
      setSelectedposition(-1);
    } else {
      // If adding new entry
      values.work_since_to_present = "no"; // Set default value for new entries
      setEmploymentArray((prevArray) => [...prevArray, values]);
    }

    // Reset form fields
    setEditVisible(false);
    formRef.current.resetFields(); // Clear form after submission
  };
  const onFinishEducation = (values) => {
    // Convert any specific values if needed
    values.passing_year = values.passing_year
      ? values.passing_year.toString()
      : "";
    values.course_type = values.course_type || "fullTime"; // Default to 'fullTime' if no value is provided
    values.qualification = values.qualification || "";
    values.course = values.course || "";
    values.university = values.university || "";

    if (selectedQualification !== -1) {
      // If editing an existing entry
      const updatedArray = [...educationArray];
      updatedArray[selectedQualification] = {
        ...updatedArray[selectedQualification],
        ...values, // Merge the updated values with the existing values
      };
      setEducationArray(updatedArray);
      setSelectedQualification(-1); // Reset the selected index
    } else {
      // If adding a new entry
      setEducationArray((prevArray) => [...prevArray, values]);
    }

    // Reset form fields and close the modal
    setVisible(false);
    educationFormRef.current.resetFields();
  };

  const getQualificationData = () => {
    return educationArray.map((qualification, index) => {
      const course = Courses.find((item) => {
        return parseInt(item.value) === parseInt(qualification.course);
      });
      return (
        <Row key={index} gutter={24} className="mb-5">
          <Col span={24} className="mb-10">
            <Title level={5} className="mb-0">
              {course ? course.label : ""}
              <Tooltip className="ml-3" placement="top" title="Edit Details">
                <EditOutlined
                  style={{ fontSize: "15px", color: "#372727" }}
                  onClick={() => {
                    showEducationDrawer(index);
                  }}
                />
              </Tooltip>
              <Tooltip className="ml-3" placement="top" title="Delete">
                <Popconfirm
                  title="Are you sure you want to delete？"
                  onConfirm={() => this.deleteItem(index)}
                  icon={<DeleteOutlined style={{ color: "red" }} />}
                >
                  <DeleteOutlined />
                </Popconfirm>
              </Tooltip>
            </Title>

            <Text type="secondary">{qualification.university}</Text>
            <br />
            <Text type="secondary">
              {qualification.passing_year} ({qualification.course_type})
            </Text>
            <br />
          </Col>
        </Row>
      );
    });
  };
  const setDefaultState = () => {
    setTimeout(() => {
      setSuccess(false);
      setLoading(false);
      setError(false);
      setMsgSuccess("");
      setmsgError("");
      setClearState();
    }, 3000);
  };

  const setDefaultStateForFile = () => {
    setTimeout(() => {
      setSuccess(false);
      setLoading(false);
      setError(false);
      setMsgSuccess("");
      setmsgError("");
    }, 3000);
  };

  const setClearState = () => {
    form.current.setFieldsValue({
      first_name: "",
      middle_name: "",
      last_name: "",
      email: "",
      mobile: "",
      total_work_exp_year: "",
      total_work_exp_month: "",
      notes: "",
      pay_type: "",
      attachments: "",
      location: "",
    });
    setSelectedFileList([]);
    setSelectedFile("");
  };

  const uploadCVToParse = {
    name: "file",
    multiple: false,
    accept:
      ".pdf,.docx,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    beforeUpload: (file) => {
      setParseLoading(true);
      if (
        file.type !== "application/pdf" &&
        file.type !==
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        message.error("You can only upload PDF or DOCX files!");
        setParseLoading(false);
        return false;
      }
      const formData = new FormData();
      formData.append("file", file);

      axios
        .post(PARSE_CV, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        // .then(response => response.json())
        .then((result) => {
          // setData(result.data.data);
          form.current.setFieldsValue({
            first_name: result.data.data.first_name,
            middle_name: result.data.data.middle_name,
            last_name: result.data.data.last_name,
            email: result.data.data.email,
            mobile: result.data.data.mobile,
            gender: result.data.data.gender,
            current_location: result.data.data.current_location,
            profile_summary: result.data.data.profile_summary,
            total_work_exp_year: result.data.data.total_work_exp_year,
            total_work_exp_month: result.data.data.total_work_exp_month,
            
            notes: result.data.data.text,
            pay_type: result.data.data.pay_type,
          });
          setParseLoading(false);
          // message.success(`${file.name} file uploaded successfully.`);
          setResumeParsed(true);
          setBottomUploadDisabled(true);
          getBase64(file, (base64File) => {
            setSelectedFile(base64File);
            setSelectedFileList([file]);
          });
        })
        .catch((error) => {
          // console.error("Error uploading file", error);
          setParseLoading(false);
          setParseFileError(true);
          // message.error(`${file.name} file upload filed.`);
        });

      return false; // Prevent automatic upload by Ant Design
    },
    disabled: resumeParsed,
  };
  const onClose = () => {
    setEditVisible(false);
    setSelectedposition(-1);
  };
  const onEducationModalClose = () => {
    setVisible(false);
    setSelectedQualification(-1);
  };

  const deleteItem = async (position) => {
    const employment = employmentArray[position];

    if (employment) {
      const updatedArray = employmentArray.filter(
        (_, index) => index !== position
      );
      setEmploymentArray(updatedArray); // Update the state with the new array
    }
  };

  const employeedata = (employers) => {
    return employmentArray.map((employer, index) => {
      // console.log(employer);
      const notice_period_elm = noticePeriod.filter(
        (el) => el.value === employer.notice_period
      );
      const months_from_elm = months.filter(
        (el) => el.value === employer.work_since_from_month
      );
      const months_to_elm = months.filter(
        (el) => el.value === employer.work_since_to_month
      );

      return (
        <Row key={index} gutter={24} className="mb-5">
          <Col span={24} className="mb-10">
            <Title level={5} className="mb-0">
              {employer.designation}
              <Tooltip className="ml-3" placement="top" title="Edit Details">
                <EditOutlined
                  style={{ fontSize: "15px", color: "#372727" }}
                  onClick={() => showDrawer(index)}
                />
              </Tooltip>
              <Tooltip className="ml-3" placement="top" title="Delete">
                <Popconfirm
                  title="Are you sure you want to delete？"
                  onConfirm={() => deleteItem(index)}
                  icon={<DeleteOutlined style={{ color: "red" }} />}
                >
                  <DeleteOutlined />
                </Popconfirm>
              </Tooltip>
            </Title>
            <Text type="secondary">{employer.organization}</Text>
            <br />
            <Text type="secondary">
              {(months_from_elm[0]?.label || "") +
                "/" +
                employer.work_since_from_year}{" "}
              to
              {!employer.is_current_company
                ? (months_to_elm[0]?.label || "") +
                  "/" +
                  employer.work_since_to_year
                : "Present"}
            </Text>
            <br />
            <Text type="secondary">
              {notice_period_elm[0]?.label
                ? `Available to join in ${notice_period_elm[0].label}`
                : ""}
            </Text>
            <br />
            <Text type="secondary">{employer.description_job_profile}</Text>
          </Col>
        </Row>
      );
    });
  };

  const onChangeCompany = (e) => {
    setCurrentCompany(e.target.value);
    editModal();
  };
  const editModal = () => {
    return (
      <Drawer
        title="Edit Employment Details"
        width={620}
        onClose={() => onClose()}
        visible={editVisible}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button onClick={() => onClose()} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button
              form="editEmployment"
              htmlType="submit"
              key="submit"
              type="primary"
            >
              Submit
            </Button>
          </div>
        }
      >
        <Form
          ref={formRef}
          id="editEmployment"
          layout="vertical"
          onFinish={onFinishEmploymentHistory}
        >
          <Card>
            {/* {showError(success, msgSuccess, error, msgError)} */}
            <Col span={16}>
              <FormItem
                label="Your Designation"
                name={"designation"}
                rules={[
                  {
                    required: true,
                    message: "Your Designation is required.",
                  },
                ]}
              >
                <Input placeholder="Your Designation" />
              </FormItem>
            </Col>
            <Col span={16}>
              <FormItem
                label="Your Organization"
                name={"organization"}
                rules={[
                  {
                    required: true,
                    message: "Your Organization is required.",
                  },
                ]}
              >
                <Input placeholder="Organization" />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Is this your current company?"
                name={"is_current_company"}
                rules={[
                  {
                    required: true,
                    message: "Please select current company.",
                  },
                ]}
              >
                <Radio.Group onChange={onChangeCompany}>
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </FormItem>
            </Col>
            <Col span={16}>
              <FormItem label="Started Working From">
                <Row gutter={24}>
                  <Col span={12}>
                    <FormItem
                      label="Years"
                      name={"work_since_from_year"}
                      rules={[
                        {
                          required: true,
                          message: "Please Select Years",
                        },
                      ]}
                    >
                      <Select>
                        <Option value={""}>{"Select"}</Option>
                        {generateArrayOfYears().map((user, index) => {
                          return (
                            <Option value={user.value} key={index}>
                              {user.label + (index < 2 ? " Year" : " Years")}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem
                      label="Months"
                      name={"work_since_from_month"}
                      rules={[
                        {
                          required: true,
                          message: "Please Select Months",
                        },
                      ]}
                    >
                      <Select>
                        <Option value={""}>{"Select"}</Option>
                        {months.map((user, index) => {
                          return (
                            <Option value={user.value} key={index}>
                              {user.label}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormItem>
                  </Col>
                </Row>
              </FormItem>
            </Col>

            {!isCurrentCompany ? (
              <Col span={16}>
                <FormItem label="Worked Till">
                  <Row gutter={24}>
                    <Col span={12}>
                      <FormItem
                        label="Years"
                        name={"work_since_to_year"}
                        rules={[
                          {
                            required: true,
                            message: "Please Select Years",
                          },
                        ]}
                      >
                        <Select>
                          <Option value={""}>{"Select"}</Option>
                          {generateArrayOfYears().map((user, index) => {
                            return (
                              <Option value={user.value} key={index}>
                                {user.label + (index < 2 ? " Year" : " Years")}
                              </Option>
                            );
                          })}
                        </Select>
                      </FormItem>
                    </Col>
                    <Col span={12}>
                      <FormItem
                        label="Months"
                        name={"work_since_to_month"}
                        rules={[
                          {
                            required: true,
                            message: "Please Select Months",
                          },
                        ]}
                      >
                        <Select>
                          <Option value={""}>{"Select"}</Option>
                          {months.map((user, index) => {
                            return (
                              <Option value={user.value} key={index}>
                                {user.label}
                              </Option>
                            );
                          })}
                        </Select>
                      </FormItem>
                    </Col>
                  </Row>
                </FormItem>
              </Col>
            ) : null}

            <Col span={24}>
              <FormItem label="Annual Salary">
                <Row gutter={24}>
                  <Col span={16}>
                    <FormItem
                      label=""
                      name={"annual_salary"}
                      rules={[
                        {
                          required: true,
                          message: "This field is required.",
                        },
                      ]}
                    >
                      <Input
                        prefix={"$"}
                        placeholder="Salary"
                        onKeyPress={(e) => {
                          if (!/[0-9]/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </FormItem>
                  </Col>
                </Row>
              </FormItem>
            </Col>

            <Col span={16}>
              <FormItem
                label="Describe your Job Profile"
                name={"description_job_profile"}
                rules={[
                  {
                    required: true,
                    message: "Job Profile is required.",
                  },
                ]}
              >
                <TextArea
                  autoSize={{ minRows: 3, maxRows: 8 }}
                  placeholder="Describe your Job Profile"
                />
              </FormItem>
            </Col>
            <Col span={16}>
              <FormItem
                label="Your Location"
                name={"location"}
                rules={[
                  {
                    required: true,
                    message: "Your Location is required.",
                  },
                ]}
              >
                <Input placeholder="Location" />
              </FormItem>
            </Col>
            <Col span={16}>
              <FormItem label="Notice Period" name={"notice_period"}>
                <Select>
                  <Option value={""}>{"Select"}</Option>
                  {noticePeriod.map((user, index) => {
                    return (
                      <Option value={user.value} key={index}>
                        {user.label}
                      </Option>
                    );
                  })}
                </Select>
              </FormItem>
              
            </Col>
            
            
          </Card>
        </Form>
      </Drawer>
    );
  };

  const editEducationModal = () => {
    return (
      <Drawer
        title="Edit Educational Details"
        width={620}
        onClose={onEducationModalClose}
        visible={visible}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div style={{ textAlign: "right" }}>
            <Button onClick={onEducationModalClose} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button
              form="editEducation"
              htmlType="submit"
              key="submit"
              type="primary"
            >
              Submit
            </Button>
          </div>
        }
      >
        <Form
          ref={educationFormRef}
          id="editEducation"
          layout="vertical"
          onFinish={onFinishEducation}
        >
          <Card>
            <Col span={16}>
              <FormItem label="Education" name="qualification">
                <Select>
                  <Option value="">Select</Option>
                  {EducationList.map((item, index) => (
                    <Option value={item.value} key={index}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </FormItem>
            </Col>

            <Col span={16}>
              <FormItem label="Course" name="course">
                <Select>
                  <Option value="">Select</Option>
                  {Courses.map((item, index) => (
                    <Option value={item.value} key={index}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </FormItem>
            </Col>

            <Col span={16}>
              <FormItem
                label="University/Institute"
                name="university"
                rules={[
                  {
                    required: true,
                    message: "Your University/Institute is required.",
                  },
                ]}
              >
                <Input placeholder="University Institute" />
              </FormItem>
            </Col>

            <Col span={24}>
              <FormItem
                label="Course Type"
                name="course_type"
                rules={[
                  { required: true, message: "Please select Course Type." },
                ]}
              >
                <Radio.Group>
                  <Radio value="fullTime">Full Time</Radio>
                  <Radio value="partTime">Part Time</Radio>
                  <Radio value="distanceLearning">
                    Correspondence/Distance learning
                  </Radio>
                </Radio.Group>
              </FormItem>
            </Col>

            <Col span={16}>
              <FormItem
                label="Passing out Year"
                name="passing_year"
                rules={[
                  { required: true, message: "Passing Year is required." },
                ]}
              >
                <Input placeholder="Passing Year" />
              </FormItem>
            </Col>
          </Card>
        </Form>
      </Drawer>
    );
  };

  return (
    <>
      <Col span={24}>
        <FormItem
          label={
            <span>
              <span style={{ color: "#ff4d4f" }}>*</span> Upload Document (input
              candidate's data automatically! Only pdf and docx)
            </span>
          }
          name={"attachments"}
        >
          <Upload {...uploadCVToParse}>
            <Button disabled={resumeParsed} icon={<UploadOutlined />}>
              UPLOAD RESUME
            </Button>
          </Upload>

          {parseFileError && (
            <p style={{ color: "#ff4d4f" }}>Please upload a document</p>
          )}
        </FormItem>
        {parseLoading && <Spin className="loading" size="large" />}
      </Col>

      <Form ref={form} id="AddCandidate" layout="vertical" onFinish={onFinish}>
        {showError(success, msgSuccess, error, msgError)}
        <Card title="Add Candidate">
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item
                label="First name"
                name="first_name"
                initialValue={data?.first_name}
                rules={[{ required: true, message: "First name is required." }]}
              >
                <Input placeholder="First name" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Middle name"
                name="middle_name"
                initialValue={data?.middle_name}
              >
                <Input placeholder="Middle name" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Last name"
                name="last_name"
                initialValue={data?.last_name}
                rules={[{ required: true, message: "Last name is required." }]}
              >
                <Input placeholder="Last name" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="Email"
                name="email"
                initialValue={data?.email}
                rules={[
                  { type: "email", message: "The input is not valid E-mail!" },
                  { required: true, message: "Email Address is required." },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Mobile"
                name="mobile"
                initialValue={data?.mobile}
                rules={[{ required: true, message: "Mobile is required." }]}
              >
                <Input placeholder="Mobile" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            {/* Profile Summary */}
            <Col span={24}>
              <Form.Item
                label="Profile Summary"
                name="profile_summary"
                initialValue={data?.profile_summary}
              >
                <Input.TextArea rows={4} placeholder="Profile Summary" />
              </Form.Item>
            </Col>

            {/* Gender */}
            <Col span={12}>
              <Form.Item
                label="Gender"
                name="gender"
                initialValue={data?.gender}
                rules={[{ required: true, message: "Please select gender" }]}
              >
                <Select placeholder="Select Gender">
                  <Option value="male">Male</Option>
                  <Option value="female">Female</Option>
                  <Option value="other">Other</Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Current Location */}
            <Col span={12}>
              <Form.Item
                label="Current Location"
                name="current_location"
                initialValue={data?.current_location}
                rules={[
                  { required: true, message: "Current location is required." },
                ]}
              >
                <Input placeholder="Current Location" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={24}>
              <Form.Item label="Total Experience">
                <Row gutter={24}>
                  <Col span={12}>
                    <Form.Item
                      label="Years"
                      name="total_work_exp_year"
                      initialValue={data?.total_work_exp_year}
                    >
                      <Select>
                        {yearsOfExpirance.map((user, index) => (
                          <Option value={user.value} key={index}>
                            {user.label + (index < 2 ? " Year" : " Years")}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Months" name="total_work_exp_month">
                      <Select>
                        {monthsOfExpirance.map((user, index) => (
                          <Option value={user.value} key={index}>
                            {user.label + (index < 2 ? " Month" : " Months")}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Input tags" name="tags">
                      <Select
                        mode="tags"
                        style={{ width: "100%" }}
                        placeholder="Input tags"
                        onChange={handleChange}
                        value={tags}
                      >
                        {tags.map((tag) => (
                          <Option key={tag} value={tag}>
                            <Tag color="blue">{tag}</Tag>
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Enter Skills" name="key_skills">
                      <Select
                        mode="tags"
                        style={{ width: "100%" }}
                        placeholder="Enter Skills"
                        onChange={handleSkillChange}
                        value={skills}
                      >
                        {skills.map((skill) => (
                          <Option key={skill} value={skill}>
                            <Tag color="blue">{skill}</Tag>
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Pay Type" name="pay_type">
                      <Select>
                        {payTypes.map((type, index) => (
                          <Option value={type} key={index.toString()}>
                            {type}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="Notes" name="notes" initialValue={data?.text}>
                <Input.TextArea rows={4} placeholder="Notes" />
              </Form.Item>
            </Col>
          </Row>

          {/* Employment and Education cards */}
          <Card
            title="Employment"
            extra={
              <Tooltip placement="top" title="Add Your Employment Details">
                <DiffOutlined
                  style={{ fontSize: "20px", color: "#372727" }}
                  onClick={() => showDrawer(-1)}
                />
              </Tooltip>
            }
          >
            {employeedata(employmentArray)}
            {editModal()}
          </Card>

          <Card
            title="Education"
            extra={
              <Tooltip placement="top" title="Add Your Education Details">
                <DiffOutlined
                  style={{ fontSize: "20px", color: "#372727" }}
                  onClick={() => showEducationDrawer(-1)}
                />
              </Tooltip>
            }
          >
            {getQualificationData()}
            {editEducationModal()}
          </Card>

          {/* Submit Button */}
          <Card>
            <Row gutter={24}>
              <Col span={24}>
                {loading ? (
                  <Button type="primary" size="large" loading>
                    Saving . .
                  </Button>
                ) : (
                  <Button type="primary" size="large" htmlType="submit">
                    Submit
                  </Button>
                )}
              </Col>
            </Row>
          </Card>
        </Card>
      </Form>
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setRole: (data) => {
    dispatch(setRole(data));
  },
  setUser: (data) => {
    dispatch(setUser(data));
  },
  setToken: (data) => {
    dispatch(setToken(data));
  },
});

export default connect(null, mapDispatchToProps)(AddCandidate);
